import { db } from '../firebase/firebaseConfig';
import { doc, setDoc, updateDoc } from 'firebase/firestore';

const addUser = async(props) => {
  const{
    fechaRegistro,
    usuario,
    rol,
    pep,
    toSelf,
    destino,
    contactos,
    fechaNacimiento,
    email,
    celular,
    numero,
    nombre,
    documento,
    redes,
    direccion,
    lastSign
  } = props
  
  return await setDoc(doc(db, 'usuarios', usuario), {
    fechaRegistro: fechaRegistro,
    usuario: usuario,
    rol: rol,
    pep: pep,
    toSelf: toSelf,
    destino: destino,
    contactos: contactos,
    fechaNacimiento: fechaNacimiento,
    email: email,
    celular: celular,
    nombre: nombre,
    numero: numero,
    documento: documento,
    redes: redes,
    direccion: direccion,
    lastSign: lastSign
  });
}

const updateUser = async(props) =>{
  const{
    usuario,
    dob,
    celular,
    nombre,
    documento,
    applicantId,
    inspectionId
  }= props;

  return await updateDoc(doc(db, 'usuarios', usuario), {
    fechaNacimiento:dob,
    celular: celular,
    nombre: nombre,
    documento: documento,
    applicantId:applicantId,
    inspectionId:inspectionId,
    actualizado:true
  })
}

const registroUsuario = async(props) => {
  const{
    usuario,
    pep,
    redes,
    direccion,
    encuestas
  } = props

  return await updateDoc(doc(db, 'usuarios', usuario), {
    pep: pep,
    redes: redes,
    direccion: direccion,
    encuestas: encuestas
  });
}

const sumsubCheck = async(props) => {
  const{
    update,
    fechaRegistro,
    usuario,
    rol,
    email,
    nombre,
    direccion,
    referido
  } = props

  if(update){
    return await updateDoc(doc(db,'usuarios', usuario),{
      verificado: 'verificada',
     
    });
  }else{
    return await setDoc(doc(db,'usuarios', usuario),{
      fechaRegistro: fechaRegistro,
      usuario: usuario,
      rol: rol,
      email: email,
      verificado: 'verificada',
      nombre: nombre,
      direccion: direccion,
      ...referido && {referido: referido},   
    });
  }
}

const deleteMe = async (id)=> {
  return await updateDoc(doc(db, 'usuarios', id),{
    removed: true
  })
}

export{
  addUser,
  updateUser,
  registroUsuario,
  sumsubCheck,
  deleteMe
}