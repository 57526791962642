import React from 'react';
import { useConfig } from '../../context/ConfigsContext';
import SVG from 'react-inlinesvg';
import Check from '../../img/icons/tick-circle.svg';
import Thick from '../../img/icons/minus-cirlce.svg';
import ColorEstado from '../../functions/ColorEstado';

const TransCard = ({data, GoinTo, CoinFrom, CoinTo, table}) => {

  const {Capitalize, dateAu, FormatCop} = useConfig();

  return (
    <div className="cardsTable">
      {data.map((transaccion)=>{
        let divMetodo = 'blueLabel';
        if(transaccion.transferencia.metodo !== 'Tradicional'){
          divMetodo = ('redLabel');
        }
        let nivel1 = false
        let nivel2 = false
        let nivel3 = false
        let pais = table
        if(table==='transferencias'){pais = 'australia'}
        if(table==='emiratos'){pais = 'emiratosArabesUnidos'}

        if(transaccion.revisiones){
          if(transaccion.revisiones.datos){nivel1 = true}
          if(transaccion.revisiones.valores){nivel2 = true}
          if(transaccion.revisiones.ingreso){nivel3 = true}
        }
        return(
          <div className="cardTable" onClick={()=>{GoinTo(transaccion.id, pais)}} key={transaccion.id}>
            <h3 className={divMetodo}>{transaccion.id.substring(0, 6).toUpperCase()+' - '+transaccion.transferencia.metodo}</h3>
            <div className='revisionsTop'>
              {nivel1 ? <SVG src={Check} className="tabCheck" /> : <SVG src={Thick} className="tabThick" />}
              {nivel2 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
              {nivel3 ? <SVG src={Check} className="tabCheck"/> : <SVG src={Thick} className="tabThick"/>}
            </div>
            <div className='infoDiv'>
              <p><label>Fecha</label>
              {dateAu(transaccion.fecha).toUpperCase()}</p>
              <p className='pElipsis'><label>Remitente</label>
              {Capitalize(transaccion.remitente.nombre) + ' ' + Capitalize(transaccion.remitente.apellido)}</p>
              <p><label>Estado</label>
              <span className={ColorEstado(transaccion.estado)}>&#9679;</span> {transaccion.estado}</p>
            </div>
            <div className='infoDiv'>
              <p><label>Valor a Recibir</label>{CoinTo(transaccion.transferencia.montoAud ? transaccion.transferencia.montoAud : transaccion.transferencia.to)}</p>
              <p><label>Valor Pagado</label>{CoinFrom(transaccion.transferencia.totalPagado ? transaccion.transferencia.totalPagado : transaccion.transferencia.from)}</p>
              <p><label>Tasa de Cambio</label>{FormatCop(transaccion.transferencia.tasa)}</p>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default TransCard;