import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useConfig } from '../../../../context/ConfigsContext';
import { RecotizarValor } from '../../../data/AdminTransfer';
import { getUnixTime } from 'date-fns';
import { Selector } from '../../../../components/inputs/Selector';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { InputAED, InputAU, InputCO, InputNZ, InputText } from '../../../../components/inputs/Input';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import ImgCoin from '../../../../img/icons/coin-1.svg';

const Recotizar = (props) =>{

  const {
    dbTable,
    FormatTo,
    FormatFrom,
    FormatFee,
    pagado,
    setPagado,
    cargos,
    setCargos,
    cuatroMil,
    setCuatroMil,
    tasa,
    setTasa,
    metodo,
    setMetodo,
    fee,
    setFee,
    montoAud,
    setMontoAud,
    id,
    idNuevo,
    rteEmail,
    rteNombre,
    razonTrans,
    setObs,
    titular,
    banco,
    bsb,
    cuenta
  } = props;

  const {OpenModal, setProcesando, FormatCop, Capitalize, dateEmail} = useConfig();
  const fecha = new Date();

  const [newSaldo, setNewSaldo] = useState(0);
  const [newCargos, setNewCargos] = useState(0);
  const [newTasa, setNewTasa] = useState(0);
  const [newFee, setNewFee] = useState(4.99);
  const [newMetodo, setNewMetodo] = useState(0);
  const [newAmmount, setNewAmmount] = useState(0);
  const [newCuatroMil, setNewCuatroMil] = useState(0);
  const [newOb, setNewOb] = useState('');

  useEffect(() => {
    setNewSaldo(pagado);
    setNewCargos(cargos);
    setNewTasa(tasa);
    setNewFee(fee);
    setNewMetodo(metodo);
    setNewAmmount(montoAud);
    setNewCuatroMil(cuatroMil)
  }, [pagado, cargos, cuatroMil, tasa, fee, metodo, montoAud]);

  //ALERTAS
  const [altNewSaldo, setAltNewSaldo] = useState('');
  const [altNewCargos, setAltNewCargos] = useState('');
  const [altNewTasa, setAltNewTasa] = useState('');
  const [altNewFee, setAltNewFee] = useState('');
  const [altObservaciones, setAltObservaciones]= useState('');
  const [spnNewSaldo, setSpnNewSaldo] = useState(false);
  const [spnCuatro, setSpnCuatro] = useState(false);
  const [spnNewCargos, setSpnNewCargos] = useState(false);
  const [spnNewTasa, setSpnNewTasa] = useState(false);
  const [spnNewFee, setSpnNewFee] = useState(false);
  const [spnMetodo, setSpnMetodo] = useState(false);
  const [spnAmount, setSpnAmmount] = useState(false);
  const [spnObservaciones, setSpnObservaciones]= useState(false);

  //METODOS
  const metodos = [{ id: '1', text: 'Tradicional' },{ id: '2', text: 'UltraRápido' },]

  useEffect(()=>{
    if(newTasa && dbTable){ 
      if(dbTable === 'colombia'){
        let calculo = (newSaldo - newCargos - newFee) * newTasa
        let milcuatro = calculo * 4 /1000
        setNewCuatroMil(milcuatro)
        setNewAmmount(Math.floor(calculo - milcuatro));
      }else{
        let milcuatro = newSaldo * 4 /1000
        let calculo = ((newSaldo - newCargos - milcuatro) / newTasa) - newFee
        setNewCuatroMil(milcuatro)
        setNewAmmount(Math.floor(calculo));
      }
    }
  },[newTasa, newFee, newSaldo, newCargos, dbTable]);

  const Validar = (e) => {
    e.preventDefault();
    if(newSaldo===''){setSpnNewSaldo(true);setAltNewSaldo('Ingresa el saldo');return;}
    if(newCargos===''){setSpnNewCargos(true);setAltNewCargos('Ingresa un Valor');return}
    if(newTasa===''){setSpnNewTasa(true); setAltNewTasa('Ingresa la nueva tasa'); return;}
    if(newFee===''){setSpnNewFee(true); setAltNewFee('Ingresa la nueva tasa'); return;}
    if(newOb===''){setSpnObservaciones(true); setAltObservaciones('Ingresa la observación'); return;}
    OpenModal(
      <ModalValidar
        accion={TransRecotizada}
        titulo={"Recotizar Transferencia"}
        mensaje={"¿Estás seguro de recotizar los valores de esta transferencia?"}
        botonName={"Sí, Recotizar"}
        botonClass={"btnGeneral primaryC"}
    />);
  };

  const TransRecotizada = () => {
    setProcesando(true);
    RecotizarValor({
      dbTable: dbTable,
      id: id,
      datos:{
        recotizado: true,
        transferencia: {
          to: newAmmount,
          from: newSaldo,
          metodo: newMetodo,
          fee: newFee,
          cargos: newCargos,
          tasa: newTasa,
          cuatromil: newCuatroMil
        },
        saldoAnterior: {
          to: montoAud,
          from: pagado,
          metodo: metodo,
          fee: fee,
          cargos: cargos,
          tasa: tasa,
          cuatromil: cuatroMil
        },
        observaciones: newOb,
      }
    }).then(async () => {
        const datos = {
          id: idNuevo,
          fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
          estado: 'Recotizada',
          nombre: Capitalize(rteNombre),
          email: rteEmail,
          metodo: newMetodo,
          fee: FormatFee(newFee),
          tasa: FormatCop(newTasa),
          aud: FormatTo(newAmmount),
          cop: FormatFrom(newSaldo),
          cargos: FormatFrom(newCargos),
          razon: razonTrans,
          titular: Capitalize(titular),
          bank: banco,
          bsb: bsb,
          account: cuenta,
          msj: 'fue Recotizada. A continuación encontrarás su información.',
          observacion: newOb
        } 

        await axios.post('https://server-colcambios.herokuapp.com/api/transferencia',{datos})
          .then((res) => {
            if (res.data.success) {
              setPagado(newSaldo);
              setCargos(newCargos);
              setTasa(newTasa);
              setMetodo(newMetodo);
              setFee(newFee);
              setMontoAud(newAmmount);
              setObs(newOb);
              setCuatroMil(newCuatroMil)
              setProcesando(false);
              OpenModal(<PopUpValid mensaje={'La transferencia fue recotizada Correctamente.'} />);
            }
          }).catch(() => {
            setProcesando(false);
            OpenModal(<HuboUnError />);
          });
      }).catch(() => {
        setProcesando(false);
        OpenModal(<HuboUnError />);
      });
  };

  return(
    <form onSubmit={Validar} className="card">
      <p className='cardTitle'>Recotizar</p>

      <Selector label={'Método'} value={newMetodo} setValue={setNewMetodo} options={metodos}
        spn={spnMetodo} setSpn={setSpnMetodo} alt='' />

      {(dbTable === 'transferencias' || dbTable === 'newzealand' || dbTable === 'emiratos' ) && <>
        <InputCO label={'Valor Pagado'} spn={spnNewSaldo} setSpn={setSpnNewSaldo} alt={altNewSaldo} 
          value={newSaldo} setValue={setNewSaldo} fill={'off'} disabled={false} />
        <InputCO label={'Cargos'} spn={spnNewCargos} setSpn={setSpnNewCargos} alt={altNewCargos} 
          value={newCargos} setValue={setNewCargos}  fill={'off'} disabled={false}/>
        <InputCO label={'Impuesto 4 x 1000'} spn={spnCuatro} setSpn={setSpnCuatro} alt={''} 
          value={newCuatroMil} setValue={setNewCuatroMil} fill={'off'} disabled={true}/>
      </>}

      {dbTable === 'colombia' && <>
        <InputAU label={'Valor Pagado'} spn={spnNewSaldo} setSpn={setSpnNewSaldo} alt={altNewSaldo} 
        value={newSaldo} setValue={setNewSaldo} fill={'off'} disabled={false} />
        <InputAU label={'Cargos'} spn={spnNewCargos} setSpn={setSpnNewCargos} alt={altNewCargos} 
        value={newCargos} setValue={setNewCargos}  fill={'off'} disabled={false}/>
      </>}

      {dbTable === 'colombia' &&
        <InputAU label={'Fee'} spn={spnNewFee} setSpn={setSpnNewFee} alt={altNewFee} 
          value={newFee} setValue={setNewFee} fill={'off'} disabled={false}/>
      }
    
      <InputCO label={'Nueva Tasa'} spn={spnNewTasa} setSpn={setSpnNewTasa} alt={altNewTasa} 
        value={newTasa} setValue={setNewTasa} fill={'off'} disabled={false} />

      {dbTable === 'transferencias' &&
        <InputAU label={'Fee'} spn={spnNewFee} setSpn={setSpnNewFee} alt={altNewFee} 
          value={newFee} setValue={setNewFee} fill={'off'} disabled={false}/>
      }

      {dbTable === 'newzealand' && 
        <InputNZ label={'Fee'} spn={spnNewFee} setSpn={setSpnNewFee} alt={altNewFee} 
          value={newFee} setValue={setNewFee} fill={'off'} disabled={false}/>
      }

      {dbTable === 'emiratos' && 
        <InputAED label={'Fee'} spn={spnNewFee} setSpn={setSpnNewFee} alt={altNewFee} 
          value={newFee} setValue={setNewFee} fill={'off'} disabled={false}/>
      }

      {dbTable === 'transferencias' &&
        <InputAU label={'Recibe'} spn={spnAmount} setSpn={setSpnAmmount} alt={''} 
          value={newAmmount} setValue={setNewAmmount} fill={'off'} disabled={true}/>
      }

      {dbTable === 'emiratos' &&
        <InputAED label={'Recibe'} spn={spnAmount} setSpn={setSpnAmmount} alt={''} 
          value={newAmmount} setValue={setNewAmmount} fill={'off'} disabled={true}/>
      }

      {dbTable === 'colombia' && <>
        <InputCO label={'Impuesto 4 x 1000'} spn={spnCuatro} setSpn={setSpnCuatro} alt={''} 
          value={newCuatroMil} setValue={setNewCuatroMil} fill={'off'} disabled={true}/>
        <InputCO label={'Recibe'} spn={spnAmount} setSpn={setSpnAmmount} alt={''} 
          value={newAmmount} setValue={setNewAmmount} fill={'off'} disabled={true}/>
      </>}

      {dbTable === 'newzealand' &&
        <InputNZ label={'Recibe'} spn={spnAmount} setSpn={setSpnAmmount} alt={''} 
          value={newAmmount} setValue={setNewAmmount} fill={'off'} disabled={true}/>
      }

      <InputText label={'Observaciones'} value={newOb} setValue={setNewOb}
		    spn={spnObservaciones} setSpn={setSpnObservaciones} alt={altObservaciones} disabled={false} />

      <BtnGeneral text='Recotizar' img={ImgCoin} side={'L'} disabled={false} type="submit" 
        action={()=>{}} clases={'primaryC'} large={true} />
    </form>
  );
}

export default Recotizar;
