import React, { useEffect, useState } from 'react';
import { useAllReports } from '../../../hooks/useReports';
import Chart from "react-apexcharts";
import { LoadingCard } from '../../../../components/loading/Loadings';
import { useConfig } from '../../../../context/ConfigsContext';
 
const Ventas = () => {

  const {FormatAud} = useConfig();
  const {results } = useAllReports();
  const [ventas, setVentas] = useState();
  const [salesN, setSalesN] = useState();
  const [compras, setCompras] = useState();
  const [buysN, setBuysN] = useState();
  const [fee, setFees] = useState();
  const [usuarios, setUsuarios] = useState();

  useEffect(() => {
    if(results){
      let sales1 = results.filter(z => z.year === '2025').map(a => Math.trunc(a.sales))
      let sales2 = results.filter(z => z.year === '2024').map(a => Math.trunc(a.sales))
      let sales3 = results.filter(z => z.year === '2023').map(a => Math.trunc(a.sales))
      let buys1 = results.filter(z => z.year === '2025').map(a => Math.trunc(a.buys))
      let buys2 = results.filter(z => z.year === '2024').map(a => Math.trunc(a.buys))
      let buys3 = results.filter(z => z.year === '2023').map(a => Math.trunc(a.buys))
      let fees1 = results.filter(z => z.year === '2025').map(a =>  Math.trunc(a.fees))
      let fees2 = results.filter(z => z.year === '2024').map(a =>  Math.trunc(a.fees))
      let meses = results.filter(z => z.year === '2023').map(a => a.mes.substring(0, 3).toUpperCase())
      let users2024 = results.filter(z => z.year === '2025').map(a => a.users)
      let users2023 = results.filter(z => z.year === '2024').map(a => a.users)
      let users2022 = results.filter(z => z.year === '2023').map(a => a.users)
      let numberSales1 = results.filter(z => z.year === '2025').map(a => Math.trunc(a.salesNumber))
      let numberSales2 = results.filter(z => z.year === '2024').map(a => Math.trunc(a.salesNumber))
      let numberSales3 = results.filter(z => z.year === '2023').map(a => Math.trunc(a.salesNumber))
      let numberBuys1 = results.filter(z => z.year === '2025').map(a => Math.trunc(a.buysNumber))
      let numberBuys2 = results.filter(z => z.year === '2024').map(a => Math.trunc(a.buysNumber))
      let numberBuys3 = results.filter(z => z.year === '2023').map(a => Math.trunc(a.buysNumber))

      setVentas({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "2025",
            data: sales1
          },
          {
            name: "2024",
            data: sales2
          },
          {
            name: "2023",
            data: sales3
          }
        ]
      })

      setCompras({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "2025",
            data: buys1
          },
          {
            name: "2024",
            data: buys2
          },
          {
            name: "2023",
            data: buys3
          }
        ]
      })

      setFees({
        options: {
          xaxis:{
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: '2024',
            data:  fees1
          },
          {
            name: '2023',
            data:  fees2
          }
        ],
      })

      setUsuarios({
        options: {
          xaxis:{
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: '2025',
            data:  users2024
          },
          {
            name: '2024',
            data:  users2023
          },
          {
            name: '2023',
            data:  users2022
          },
        ],
      })

      setSalesN({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "2025",
            data: numberSales1
          },
          {
            name: "2024",
            data: numberSales2
          },
          {
            name: "2023",
            data: numberSales3
          }
        ]
      })

      setBuysN({
        options: {
          xaxis: {
            categories: meses
          },
          stroke:{
            curve: "smooth",
            width: 3
          },
          dataLabels: {
            enabled: false,
          },
          chart: {
            zoom: {
              enabled: false
            },
          },
        },
        series: [
          {
            name: "2025",
            data: numberBuys1
          },
          {
            name: "2024",
            data: numberBuys2
          },
          {
            name: "2023",
            data: numberBuys3
          }
        ]
      })
    }
  }, [results, FormatAud]);

  return (
    <div className='cardFlex2'>
      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>AUD Ventas</p>
          {ventas ? 
            <Chart
              options={ventas.options}
              series={ventas.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>No. de Ventas</p>
          {salesN ? 
            <Chart
              options={salesN.options}
              series={salesN.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>AUD Compras</p>
          {compras ? 
            <Chart
              options={compras.options}
              series={compras.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>No. de Compras</p>
          {buysN ? 
            <Chart
              options={buysN.options}
              series={buysN.series}
              width='100%'
              type='area'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Servicio</p>
          {fee ?
            <Chart
              options={fee.options}
              series={fee.series}
              type='area'
              width='100%'
            />
            : <LoadingCard />
          }
        </div>
      </div>

      <div className='cardFlex2Div'>
        <div className='card'>
          <p className='cardTitle'>Usuarios</p>
          {usuarios ?
            <Chart
              options={usuarios.options}
              series={usuarios.series}
              type='area'
              width='100%'
            />
            : <LoadingCard />
          }
        </div>
      </div>
    </div>
  );
}

export default Ventas;