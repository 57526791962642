import React from 'react';
import { Document, Page, Text, Font, Image, View, StyleSheet } from '@react-pdf/renderer';
import { numericFormatter } from 'react-number-format';
import LightInter from "../../fonts/Inter-Light.ttf";
import RegularInter from "../../fonts/Inter-Regular.ttf";
import BoldInter from "../../fonts/Inter-Bold.ttf";
import BlackInter from "../../fonts/Inter-Black.ttf";
import Colcambios from "../../img/icons/colcambios.png";

import Facebook from '../../img/icons/facebook.png';
import Instragram from '../../img/icons/instagram.png';
import Austrac from '../../img/icons/austrac.png';

Font.register({
	family: "Inter",
	fontStyle: "normal",
	fontWeight: "normal",
	fonts: [
		{
			src:  RegularInter,
		},
		{
			src: LightInter,
			fontWeight: "light",
		},
		{
			src: BoldInter,
			fontWeight: "bold",
		},
		{
			src: BlackInter,
			fontWeight: "black",
		},
	]
});

const styles = StyleSheet.create({
	Page: {
		paddingHorizontal: 40,
		paddingTop: 40,
		paddingBottom: 110,
	},

	Header:{
		display: 'flex',
		flexDirection:'row',
		justifyContent:'space-between',
	},
	HeaderCol:{
		display: 'flex',
		flexDirection:'row',
	},
	logoCol:{
		height: 38,
		width: 52,
		marginRight: 10,
		marginTop: 5
	},
	Text11B:{
		fontFamily: "Inter",
		fontWeight: "bold",
		lineHeight: 1.2,
		fontSize: 10,
		color: '#1E1E1E',
	},
	Text9N:{
		fontFamily: "Inter",
		fontWeight: "normal",
		lineHeight: 1.2,
		fontSize: 9,
		color: '#1E1E1E',
	},
	logoAgencia:{
		height: 45,
		width: 100
	},

	DocData:{
		paddingTop: 30,
		paddingBottom: 15,
		borderBottom: '1px solid #d8d9da',
		marginBottom: 10,
    display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
    alignItems:'flex-end'
	},
	Text20B:{
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 20,
	},
	Text15B:{
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 15,
		color: '#868789'
	},

	tableHead:{
		backgroundColor: '#F5F5F5',
		borderRadius: 5,
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 10,
		color: '#1E1E1E',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingHorizontal: 17,
		paddingVertical: 8,
		marginBottom: 5
	},
	tableRow:{
		display:'flex',
		justifyContent: 'space-between',
    alignItems: 'center',
		flexDirection: 'row',
		fontFamily: "Inter",
		fontWeight: "normal",
		fontSize: 10,
		paddingVertical: 10,
		paddingHorizontal: 17,
	},
  tableFee:{
    display: 'flex',
    flexDirection: 'column',
    alignItems:'flex-end',
    paddingTop: 10,
    paddingBottom: 6,
    borderTop: '1px solid #d8d9da',
    paddingHorizontal: 17,
  },
  feeItem:{
    width: 200,
    display:'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
		fontFamily: "Inter",
		fontWeight: "normal",
		fontSize: 10,
    marginVertical: 2,
  },
	tableFooter:{
		display:'flex',
		justifyContent: 'flex-end',
		flexDirection: 'row',
		borderTop: '1px solid #d8d9da',
		marginTop: 5,
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 12,
		paddingHorizontal: 17,
		paddingVertical: 10
	},
	total:{
		marginLeft: 10
	},

	DataRow:{
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 10,
    
	},
  DataCol:{
    marginRight: 20
  },
  DataTitle:{
    fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 13,
		color: '#868789',
		marginBottom: 2,
  },
	DataLabel:{
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 10,
		color: '#1E1E1E',
		marginBottom: 2,
	},
	DataText:{
		fontFamily: "Inter",
		fontWeight: "light",
		fontSize: 10,
		color: '#1E1E1E',
	},

	instructions:{
		backgroundColor: '#F5F5F5',
		borderRadius: 5,
		paddingVertical: 20,
	},
	intruction:{
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	Text13B:{
		fontFamily: "Inter",
		fontWeight: "bold",
		fontSize: 14,
		color: '#1E1E1E',
		marginBottom: 10,
		marginLeft: 17
	},
	Listas:{
		display:'flex',
		flexDirection: 'row',
		width: 480,
	},
	Text8N:{
		fontFamily: "Inter",
		fontWeight: "light",
		textAlign: "left",
		fontSize: 10,
		flexWrap: 'wrap'
	},
	Text8D:{
		width: 15,
		fontFamily: "Inter",
		fontWeight: "light",
		fontSize: 10,
		color: '#1E1E1E',
		marginRight: 5
	},
	Text7N:{
		fontFamily: "Inter",
		fontWeight: "light",
		fontSize: 8,
		color: '#1E1E1E',
	},
	Text7D:{
		fontFamily: "Inter",
		fontWeight: "light",
		fontSize: 8,
		color: '#1E1E1E',
		marginRight: 5
	},

	cardsBank:{
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'center',
		marginVertical: 10,
	},
	cardBank:{
		width: '46%',
		border: '1px solid #c5c6c8',
		borderRadius: 8,
		fontSize: 10,
		paddingHorizontal: 18,
		paddingVertical: 10,
		marginHorizontal: 5,
		marginVertical: 5,
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center'
	},
	bancoIcon:{
		width: 26,
		borderRadius: 5,
		marginRight: 15
	},

	textBold:{
		fontFamily: "Inter",
		fontWeight: "bold",
	},

	Footer:{
		width: '100%',
		position: 'absolute',
		bottom: 0,
		fontFamily: "Inter",
		fontWeight: "normal",
		fontSize: 9,
		paddingBottom: 30,
		marginLeft: 40,
		display: 'flex',
		flexDirection: 'column',
	},
	FooterCont:{
		display: 'flex',
		flexDirection: 'row',
		justifyContent:'space-between',
		alignItems: 'flex-end',
	},
	legal:{
		fontFamily: "Inter",
		fontWeight: "normal",
		textAlign: 'center',
		fontSize: 9,
		marginBottom: 10
	},
  red:{
		display: 'flex',
		flexDirection: 'row',
	},
	media:{
		width: 10,
		height: 10,
		marginRight: 5,
		marginVertical:1
	},
  austrac:{
		width: 250,
		height: 32,
		marginBottom: 20,
	},
	pageNumbers: {
		position: 'absolute',
		bottom: 32,
		right: 40,
		fontFamily: "Inter",
		fontWeight: "normal",
		fontSize: 8,
  },

})

const ReceiptEs = (props) => {
  const {
		table,
		externo,
    tipo,
		Idioma,
		empresa,
		logo,
    fecha,
    numerorecibo,
    nombre,
    id,
    razon,
    razonEn,
    telefono,
    email,
    banco,
    titular,
    numerobsb,
    numerocuenta,
    montocop,
    cargos,
		cuatromil,
    tasadecambio,
    metodo,
    fee,
    montoaud,
  } = props
	
	const FormatCop = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:'.', decimalSeparator:',', prefix:'$', suffix:' COP', decimalScale:'2'}) 
	}

	const FormatAud = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AUD', decimalScale:'2'})
	}

	const FormatNzd = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' NZD', decimalScale:'2'})
	}

	const FormatEau = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AED', decimalScale:'2'})
	}

	const CoinTo = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatCop(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
		if(table === 'emiratos'){return FormatEau(number)}
  }

  const CoinFrom = (number) =>{
    if(table === 'transferencias'){return FormatCop(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatCop(number)}
		if(table === 'emiratos'){return FormatEau(number)}
  }

  const CoinFee = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
		if(table === 'emiratos'){return FormatEau(number)}
  }

	return(
    <Document>
      <Page size="A4" style={styles.Page}>

				<View style={styles.Header}>
					<View style={styles.HeaderCol}>
						<Image style={styles.logoCol} src={Colcambios}></Image>
						<View>
							<Text style={styles.Text11B}>COLCAMBIOS AUSTRALIA</Text>
							<Text style={styles.Text9N}>Sydney CBD 2000</Text>
							<Text style={styles.Text9N}>+61 481 878 09</Text>
							<Text style={styles.Text9N}>info@colcambiosaustralia.com</Text>
						</View>
					</View>

					{empresa && 
					<View><Image style={styles.logoAgencia} 
            src={{ uri: `/assets/empresas/${logo}`, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }}>
          </Image></View>
          }
				</View>

        <View style={styles.DocData}>
          <View>
            <Text style={styles.Text20B}>{Idioma === 'En' ? 'Receipt' : 'Recibo de Pago'}</Text>
            <Text style={styles.Text15B}>{numerorecibo}</Text>
          </View>
          <View>
            <Text style={styles.DataLabel}>{Idioma === 'En' ? 'Receipt Date:' : 'Fecha:'} <Text style={styles.DataText}>{fecha}</Text></Text>
          </View>
        </View>

				{externo ? 
					<View style={styles.DataRow}>
						<View style={styles.DataCol}>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Name:' : 'Nombre:'} <Text style={styles.DataText}>{nombre}</Text></Text>
							<Text style={styles.DataLabel}>ID: <Text style={styles.DataText}>{id}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Phone:' : 'Teléfono:'}  <Text style={styles.DataText}>{telefono}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Email:' : 'Correo:'}  <Text style={styles.DataText}>{email}</Text></Text>
						</View>
					</View>
				:	
					<View style={styles.DataRow}>
						<View style={styles.DataCol}>
							<Text style={styles.DataTitle}>{Idioma === 'En' ? 'Sender' : 'Remitente'}</Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Name:' : 'Nombre:'} <Text style={styles.DataText}>{nombre}</Text></Text>
							<Text style={styles.DataLabel}>ID: <Text style={styles.DataText}>{id}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Phone:' : 'Teléfono:'}  <Text style={styles.DataText}>{telefono}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Email:' : 'Correo:'}  <Text style={styles.DataText}>{email}</Text></Text>
						</View>
						<View style={styles.DataCol}>
							<Text style={styles.DataTitle}>{Idioma === 'En' ? 'Receiver' : 'Destinatario'}</Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Account Name:' : 'Titular:'} <Text style={styles.DataText}>{titular}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Bank:' : 'Banco:'} <Text style={styles.DataText}>{banco}</Text></Text>
							<Text style={styles.DataLabel}>BSB: <Text style={styles.DataText}>{numerobsb}</Text></Text>
							<Text style={styles.DataLabel}>{Idioma === 'En' ? 'Account Number:' : 'Número de Cuenta:'} <Text style={styles.DataText}>{numerocuenta}</Text></Text>
						</View>
					</View>
				}
      
        <View>
          <View style={styles.tableHead}>
            <Text>{Idioma === 'En' ? 'Description' : 'Descripción'}</Text>
            <Text>{Idioma === 'En' ? 'Amount' : 'Valor'}</Text>
          </View>

          {tipo === 'multipago' ? 
            <View style={styles.tableRow}>
              <Text>Pago de estudios y visa. Estudiante 85985.</Text>
              <Text>$15.579.300 COP</Text>
            </View>
          : <View>
              <View style={styles.tableRow}>
                <Text><Text style={styles.textBold}>{Idioma === 'En' ? 'Consignment' : 'Transferencia'} {metodo === 'Tradicional' ? 
                Idioma === 'En' ? 'Traditional' : 'Tradicional' : Idioma === 'En' ? 'UltraFast' : 'UltraRápida' }</Text> {'\n'}
                <Text style={styles.textBold}>{Idioma === 'En' ? 'Reason:' : 'Razón:'}</Text> <Text>{Idioma === 'En' ? razonEn : razon}</Text></Text>
                
                <Text>{CoinFrom(montocop)}</Text>
              </View>
              <View style={styles.tableFee}>
								{table === 'colombia' && <>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Service' : 'Servicio'}</Text>
										<Text>{CoinFee(fee)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Bank costs' : 'Costos Bancarios'}</Text>
										<Text>{CoinFrom(cargos)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Exchange rate' : 'Tasa'}</Text>
										<Text>{FormatCop(tasadecambio)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Tax (4x1000)' : 'Impuesto (4x1000)'}</Text>
										<Text>{FormatCop(cuatromil)}</Text>
									</View>
								</>}

								{table !== 'colombia' && <>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Bank costs' : 'Costos Bancarios'}</Text>
										<Text>{CoinFrom(cargos)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Tax (4x1000)' : 'Impuesto (4x1000)'}</Text>
										<Text>{FormatCop(cuatromil)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Exchange rate' : 'Tasa'}</Text>
										<Text>{FormatCop(tasadecambio)}</Text>
									</View>
									<View style={styles.feeItem}>
										<Text>{Idioma === 'En' ? 'Service' : 'Servicio'}</Text>
										<Text>{CoinFee(fee)}</Text>
									</View>
								</>}
              </View>
            </View>
          }
          
          <View style={styles.tableFooter}>
            <Text>{Idioma === 'En' ? 'Received amount' : 'Valor Recibido'}</Text>
            <Text style={styles.total}>{CoinTo(montoaud)}</Text>
          </View>
        </View>

        {tipo === 'multipago' ?
          <View style={styles.instructions} wrap={false}>
            <Text style={styles.Text13B}>{Idioma === 'En' ? 'Payment Instructions' : 'Instrucciones de Pago'}</Text>
            <View style={styles.intruction}>
              <View style={styles.Listas}>
                <Text style={styles.Text8D}>1.</Text>
                {Idioma === 'En' ?
                <Text style={styles.Text8N}>Transfer or deposit in any of the bank accounts listed below. <Text style={styles.textBold}>Preferably between accounts of{'\n'}
                the same bank</Text> to avoid setbacks or additional costs.
                </Text>
                :
                <Text style={styles.Text8N}>Transfiera o consigne en cualquiera de las cuentas bancarias relacionadas en la parte inferior.{'\n'} 
                <Text style={styles.textBold}>Preferiblemente entre cuentas del mismo banco</Text> para evitar contratiempos o costos adicionales.
                </Text>}
              </View>
              <View style={styles.Listas}>
                <Text style={styles.Text8D}>2.</Text>
                
                {Idioma === 'En' ?
                <Text style={styles.Text8N}>
                Upload the receipt to the transaction registered on the Colcambios Australia platform. If it is a{'\n'} 
                payment made by a third party send proof of payment to <Text style={styles.textBold}>info@colcambiosaustralia.com.</Text>
                </Text>
                :
                <Text style={styles.Text8N}>
                  Cargue el comprobante a la transacción registrada en la plataforma. Si es un pago realizado{'\n'} 
                  por un tercero enviar el comprobante de pago a <Text style={styles.textBold}>info@colcambiosaustralia.com.</Text>
                </Text>}
              </View>


              <View>
                <View style={styles.Listas}>
                  <Text style={styles.Text7D}>•</Text>
                  <Text style={styles.Text7N}>{Idioma === 'En' ? 
                  'The recipient or the agency will receive the money according to the type of service chosen during the registration of the transaction on the Colcambios Australia platform.'
                  :'El destinatario o la agencia recibirá el dinero de acuerdo al tipo de servicio elegido durante el registro de la transacción en la plataforma de Colcambios Australia.'}</Text>
                </View>
                <View style={styles.Listas}>
                  <Text style={styles.Text7D}>•</Text>
                  <Text style={styles.Text7N}>{Idioma === 'En' ? 
                  'The payment of this invoice outside the established time will be subject to a requote of the service.'
                  :'El pago de esta factura fuera del tiempo establecido estará sujeto a una recotización del servicio.'}</Text>
                </View>
                <View style={styles.Listas}>
                  <Text style={styles.Text7D}>•</Text>
                  <Text style={styles.Text7N}>{Idioma === 'En' ? 
                  'By paying this invoice you are accepting the terms and conditions of Colcambios Australia Pty Ltd that you can consult at '
                  :'Pagando esta factura estas aceptando los términos y condiciones de Colcambios Australia Pty Ltd que podrás consultar en '}<Text style={styles.textBold}>www.colcambiosaustralia.com.</Text></Text>
                </View>
              </View>
            </View>
          </View>
          :
          <View style={styles.instructions} wrap={false}>
            <View style={styles.intruction}>
              <View>
                <View style={styles.Listas}>
                  <Text style={styles.Text7D}>•</Text>
                  <Text style={styles.Text7N}>{Idioma === 'En' ? 
                  'The receiver or the agency will receive the money according to the type of service chosen during the registration of the transaction on the Colcambios Australia platform.'
                  :'El destinatario o la agencia recibirá el dinero de acuerdo al tipo de servicio elegido durante el registro de la transacción en la plataforma de Colcambios Australia.'}</Text>
                </View>
              </View>
            </View>
          </View>
        }

        <View style={styles.Footer} fixed>
          <Text style={styles.legal}>{Idioma === 'En' ? 'This receipt was generated through the Colcambios Australia platform.' 
          : 'Este recibo de pago fue generado a través de la plataforma de Colcambios Australia.'}</Text>
          <View style={styles.FooterCont}>
            <View>
              <Text style={styles.textBold}>Colcambios Australia Pty Ltd</Text>
              <Text><Text style={styles.textBold}>ABN</Text> 61 634 415 535</Text>
              <View style={styles.red}><Image style={styles.media} src={Instragram}></Image><Text>@colcambiosau</Text></View>
              <View style={styles.red}><Image style={styles.media} src={Facebook}></Image><Text>Colcambios Australia</Text></View>
              <Text style={styles.textBold}>www.colcambiosaustralia.com</Text>
            </View>
            <View>
                <Image style={styles.austrac} src={Austrac}></Image>
            </View>
          </View>
        </View>
        <Text  style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (`Pag ${pageNumber} / ${totalPages}`)} fixed />
      </Page>    
    </Document>
	)
}

export {
	ReceiptEs
}