import React, { useState } from 'react';
import axios from 'axios';
import { storage } from '../../../../firebase/firebaseConfig';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { AgregarComprobante } from '../../../../data/ManageTransfer';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { useConfig } from '../../../../context/ConfigsContext';
import { ModalValidar } from '../../../../components/modales/Modales';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import { UpLoadDoc } from '../../../../components/upload/uploadDoc';
import ImgOk from '../../../../img/icons/arrow-up-2.svg';
import CountDownTrans from '../../../../components/countDown/CountDownTrans';
import ColcaBanks from './ColcaBanks';

const Comprobante = (props) =>{

  const{
    dbTable,
    colcambios,
    fechaMaxPago,
    fechaTransfer,
    docRef,
    uidUsuario,
    rteNombre,
    rteEmail,
    total,
    banco,
    bsb,
    titular,
    cuenta,
    emailAud,
    emailTasa,
    razon,
    metodo,
    empresa
  } = props

  const {OpenModal, dateEmail, setProcesando, FormatAud, FormatCop, FormatNzd, FormatEau, Capitalize} = useConfig();

  const MonedaTo = (valor) =>{
    if(dbTable === 'transferencias'){return FormatAud(valor)}
    if(dbTable === 'newzealand'){return FormatNzd(valor)}
    if(dbTable === 'colombia'){return FormatCop(valor)}
    if(dbTable === 'emiratos'){return FormatEau(valor)}
  }
  const MonedaFrom = (valor) =>{
    if(dbTable === 'transferencias'){return FormatCop(valor)}
    if(dbTable === 'newzealand'){return FormatCop(valor)}
    if(dbTable === 'colombia'){return FormatAud(valor)}
    if(dbTable === 'emiratos'){return FormatCop(valor)}
  }

  //SUBIR COMPROBANTE
  const [file, setFile] = useState('');
  const [altFile, setAltFile] = useState('');
  const [spnFile, setSpnFile] = useState(false);
  const [progress, setProgress] = useState(0);

  const SubirComprobante = () =>{
    setProcesando(true)  
    const idNuevo = docRef.substring(0, 6).toUpperCase();
    const comprobante = file[0];
    const nombreArchivo = `comprobante${docRef}.${comprobante.name.split(".").pop()}`
    const storageRef = ref(storage, `${empresa ? 'empresas/'+uidUsuario : uidUsuario}/transferencias/${nombreArchivo}`);
    const uploadTask = uploadBytesResumable(storageRef, comprobante);
    uploadTask.on("state_changed",(snapshot) => {
      const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      setProgress(progress);
    },
    (error) => {
      setProcesando(false)
      OpenModal(<HuboUnError />);
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then((url) => {
        AgregarComprobante({
          id: docRef,
          table: dbTable, 
          comprobantePago: url, 
          estado:'En Proceso'
        }).then(async() =>{
          const datos = {
            id: idNuevo,
            fecha: dateEmail(fechaTransfer).toUpperCase(),
            estado: 'En Proceso',
            nombre: Capitalize(rteNombre),
            email: rteEmail,
            metodo: metodo,
            tasa: FormatCop(emailTasa),
            aud: MonedaTo(emailAud),
            cop: MonedaFrom(total),
            razon: razon,
            titular: Capitalize(titular),
            bank: banco,
            bsb: bsb,
            account: cuenta,
            msj: 'se encuentra ahora en proceso, el comprobante se ha cargado correctamente. A continuación encontrarás su información.'
          } 
  
            await axios.post('https://server-colcambios.herokuapp.com/api/transferencia',{datos}) 
            .then(res =>{
              if(res.data.success){
                setProcesando(false) 
                OpenModal(<PopUpValid mensaje={'Se ha cargado el comprobante Correctamente.'}/>);
              }
            }).catch(() =>{
              setProcesando(false)  
              OpenModal(<HuboUnError />); 
            })
        }).catch(() =>{
          setProcesando(false)  
          OpenModal(<HuboUnError/>);
        })
      })
    })
  }

  const Validar = (e) => {
    e.preventDefault();
    if(!file){setSpnFile(true); setAltFile("Selecciona el Archivo"); return;}
    if(!file[0].name.match(/\.(jpg|JPG|jpeg|png|pdf|heic|HEIC|heif|HEIF)$/)){
      setSpnFile(true);setAltFile("Archivo no válido, solo Imagen o PDF"); return;}
    const size = Math.round(file[0].size / 1024);
    if (size >= 1024) {setSpnFile(true);setAltFile("El tamaño máximo del archivo es de 1mb"); return;}
    OpenModal(
      <ModalValidar
        accion={SubirComprobante}
        titulo={"Cargar Comprobante"}
        mensaje={"¿Estás segur@ de cargar el comprobante?"}
        botonName={"Sí, Cargar"}
        botonClass={"btnGeneral primaryC"}
      />
    );
  };

  return(
    <div className='card'>
      <p className='cardTitle'>Pago de Transferencia</p>
      <p className='textP'>Si el comprobante es cargado después de haber terminado el tiempo establecido, la transferencia estará sujeta a una recotización.</p>
      {fechaMaxPago && <CountDownTrans fechaPago={fechaMaxPago}/>}
      <ColcaBanks colcambios={colcambios} country={dbTable} />
      <UpLoadDoc archivo={file} setArchivo={setFile} spn={spnFile} setSpn={setSpnFile} alt={altFile} />
      
      <div className="noSee"><progress value={progress} max="100"></progress></div>
      <div className="formButtons">
        <p></p>
        <BtnGeneral text='Cargar Pago' img={ImgOk} side={'R'} disabled={false} type="submit" 
          action={Validar} clases={'primaryC'} large={false} />
      </div>
    </div>
  )
}

export default Comprobante;