import React, { useState, useEffect, useContext} from 'react';
import useRate from '../hooks/useRate';
import { useIdleTimer } from 'react-idle-timer';
import { useGetFees } from '../hooks/useGetInfo';
import useGetCoins from '../hooks/useGetCoins';

var ApiConfig = {
	endPoint: process.env.REACT_APP_CURRENCIES_ENDPOINT,
	apiKey: process.env.REACT_APP_CURRENCIES_ACCESS_KEY,
	apiCurrency: process.env.REACT_APP_APY_CURRENCY
}

const BASE_URL = `https://apilayer.net/api/${ApiConfig.endPoint}?access_key=${ApiConfig.apiKey}&currencies=${ApiConfig.apiCurrency}`

const TransferContext = React.createContext();

const useTrans = () => {
	return useContext(TransferContext);
}

const TransProvider = ({children}) => {
	const {rate, rates, tasa, tasaAuto, descuento, rutas} = useRate();
	const [fees] = useGetFees();
	const {coins} = useGetCoins();
	
	const [ammounts, setAmmounts] = useState();

	const [cop, setCop] = useState(0);
	const [aud, setAud] = useState(0);
	const [nzd, setNzd] = useState(0);
	const [aed, setAed] = useState(0);
	const [tasas, setTasas] = useState()

	const [tasadeCambio, setTasadeCambio] = useState();
	const [tasaOriginal, setTasaOriginal] = useState();

	const [asesor, setAsesor] = useState('');
	const [promo, setPromo] = useState('');

	const truncate = (n, digits) => {
		var re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
			m = n.toString().match(re);
		return m ? parseFloat(m[1]) : n.valueOf();
	};

	useEffect(()=>{
		if(tasaAuto){
			const tasaApi = () =>{
				fetch(BASE_URL).then(res => res.json())
				.then(data => {
					setCop(data.quotes['USDCOP']);
					setAud(data.quotes['USDAUD']);
					setNzd(data.quotes['USDNZD']);
					setAed(data.quotes['USDAED'])
				})
			} 
			tasaApi()
		}
	},[tasaAuto])


	useEffect(()=>{
		if(aud && rates){
			const equipo = () =>{
				const resultado = truncate((cop/aud),2)
				const plus = truncate((resultado * rate),2);
				const result = Math.ceil(parseFloat(resultado + plus))

				setTasaOriginal(resultado)
				setTasadeCambio(result)
			
				setTasas({
					COP: {
						AUD: Math.ceil(parseFloat(truncate(cop/aud,2))),
						NZD: Math.ceil(parseFloat(truncate(cop/nzd,2))),
						AED: Math.ceil(parseFloat(truncate(cop/aed,2))),
					},

					AUD: {
						original: Math.ceil(parseFloat(truncate(cop/aud,2))),
						compra: Math.floor(parseFloat(truncate(cop/aud - (cop/aud * rates.AUD.compra),2))),
						venta: Math.ceil(parseFloat(truncate(cop/aud + (cop/aud * rates.AUD.venta),2))),

						COP: Math.ceil(parseFloat(truncate(aud/aud,2))),
						NZD: Math.ceil(parseFloat(truncate(nzd/aud,2))),
						AED: Math.ceil(parseFloat(truncate(aed/aud,2))),
					},
					AED: {
						original: Math.ceil(parseFloat(truncate(cop/aed),2)), 
						compra: Math.floor(parseFloat(truncate(cop/aed - (cop/aed * rates.AED.compra),2))),
						venta: Math.ceil(parseFloat(truncate(cop/aed + (cop/aed * rates.AED.venta),2))),

						COP: Math.ceil(parseFloat(truncate(aed/cop,2))),
						NZD: Math.ceil(parseFloat(truncate(aed/nzd,2))),
						AUD: Math.ceil(parseFloat(truncate(aed/aud,2))),
					},
					NZD: {
						original: Math.ceil(parseFloat(truncate(cop/nzd),2)), 
						compra: Math.floor(parseFloat(truncate(cop/nzd - (cop/nzd * rates.NZD.compra),2))),
						venta: Math.ceil(parseFloat(truncate(cop/nzd + (cop/nzd * rates.NZD.venta),2))),

						COP: Math.ceil(parseFloat(truncate(nzd/cop,2))),
						AUD: Math.ceil(parseFloat(truncate(nzd/aud,2))),
						AED: Math.ceil(parseFloat(truncate(nzd/aed,2))),
					}
				})
			}
			equipo();
		}
	},[aud, cop, nzd, aed, rate, rates])

	const CapturarTasa = () =>{
		const tasaApi = () =>{
			fetch(BASE_URL).then(res => res.json())
			.then(data => {
				setCop(data.quotes['USDCOP']);
				setAud(data.quotes['USDAUD']);
				setNzd(data.quotes['USDNZD']);
				setAed(data.quotes['USDAED'])
			})
		}
		return tasaApi;
	}
    
	const handleOnActive = () => {
		if(tasaAuto){
			const fijarTasa = () =>{
				fetch(BASE_URL).then(res => res.json())
				.then(data => {
					setCop(data.quotes['USDCOP']);
					setAud(data.quotes['USDAUD']);
					setNzd(data.quotes['USDNZD']);
					setAed(data.quotes['USDAED'])
				})
			}
			return fijarTasa;
		}
	}

	useIdleTimer({onActive: handleOnActive, timeout: 900000,})

	const localData = localStorage.getItem('asesor')
	const localPromo = localStorage.getItem('promo');

	useEffect(()=>{
		if(localData){setAsesor(localData)}else{setAsesor('')}
	},[localData])

	useEffect(()=>{
		if(localPromo){setPromo(localPromo)}else{setPromo('')}
	},[localPromo])


	return(
		<TransferContext.Provider value={{
			ammounts,
			setAmmounts,
			coins,
			fees,
			rate,
			rates,
			tasaAuto,
			descuento,
			tasas,
			tasa,
			tasadeCambio,
			tasaOriginal,
			truncate,
			CapturarTasa,
			asesor,
			setAsesor,
			promo,
			setPromo,
			rutas
		}}>{children}
		</TransferContext.Provider>
	);
}

export {TransProvider, TransferContext, useTrans};