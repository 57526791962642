import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useGetTransfer } from '../../../client/hooks/useGetTransfers';
import Comprobante from './components/Comprobante';
import CardDoc from '../../../components/cards/CardDoc';
import { LoadingCard } from '../../../components/loading/Loadings';
import ExportInvoice from './functions/ExportInvoice';
import { useConfig } from '../../../context/ConfigsContext';

const PagarTransfer = () => {

	const {Capitalize, FormatCop, FormatAud, FormatNzd, FormatEau} = useConfig();
  const {country, id} = useParams();
	const [table, setTable] = useState();
	
  const [transferencia, loading] = useGetTransfer(id, table);
  const [comprobante, setComprobante] = useState('');

	useEffect(()=>{
		if(country){
			if(country === 'australia'){setTable('transferencias')}
			if(country === 'colombia'){setTable('colombia')}
			if(country === 'newzealand'){setTable('newzealand')}
			if(country === 'emiratosArabesUnidos'){setTable('emiratos')}
		}
	},[country])

	useEffect(() => {
		if(transferencia){
			setComprobante(transferencia.comprobantePago)
		}
	}, [transferencia]);

	const CoinTo = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatCop(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
		if(table === 'emiratos'){return FormatEau(number)}
  }

  const CoinFrom = (number) =>{
    if(table === 'transferencias'){return FormatCop(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatCop(number)}
		if(table === 'emiratos'){return FormatCop(number)}
  }

  const CoinFee = (number) =>{
    if(table === 'transferencias'){return FormatAud(number)}
    if(table === 'colombia'){return FormatAud(number)}
    if(table === 'newzealand'){return FormatNzd(number)}
		if(table === 'emiratos'){return FormatEau(number)}
  }

  return loading ? <LoadingCard /> : transferencia &&
		<div className='dashPage'>
			<Helmet><title>Pagar Transferencia</title></Helmet>
			<p className='pageTitle'>Transferencia</p>
			
			<div className="card">
				<p className='cardTitle'>Resumen</p>
				<div className='itemCd2'>
					<label>Transferencia</label>
					<div>
						<p><b>Ticket: </b>{id.substring(0, 6).toUpperCase()}</p>
						<p><b>Estado: </b>{transferencia.estado}</p>
						<p><b>Método: </b>{transferencia.transferencia.metodo}</p>
						<p><b>Razón: </b>{transferencia.razon}</p>
					</div>
				</div>

				{transferencia.cliente &&
					<div className='itemCd2'>
						<label>Datos del Cliente</label>
						<div>
							<p><b>Nombre: </b>{transferencia.cliente.nombre}</p>
							<p><b>Celular: </b>{transferencia.cliente.phone}</p>
							<p><b>Email: </b>{transferencia.cliente.email}</p>
							<p><b>Tipo de Documento: </b>{transferencia.cliente.tipoDoc}</p>
							<p><b>Número de Documento: </b>{transferencia.cliente.numeroDoc}</p>
						</div>
				</div>}

				{/* <div className='itemCd2'>
					<label>Destinatario</label>
					<div>
						<p><b>Nombre: </b>{Capitalize(transferencia.destinatario.nombre+' '+transferencia.destinatario.apellido)}</p>
						<p><b>Celular: </b>{transferencia.destinatario.celular}</p>
						<p><b>Email: </b>{transferencia.destinatario.email}</p>
						<p><b>Tipo de Documento: </b>{transferencia.destinatario.tipoId}</p>
						<p><b>Número de Documento: </b>{transferencia.destinatario.numeroId}</p>
					</div>
				</div> */}
				<div className='itemCd2'>
					<label>Cuenta Destino</label>
					<div>
						<p><b>Titular: </b>{Capitalize(transferencia.cuenta.titular)}</p>
						<p><b>Banco: </b>{transferencia.cuenta.banco}</p>
						{transferencia.cuenta.bsb && <p><b>BSB: </b>{transferencia.cuenta.bsb}</p>}
						{transferencia.cuenta.iban && <p><b>IBAN: </b>{transferencia.cuenta.iban}</p>}
						{transferencia.cuenta.tipo && <p><b>Tipo de Cuenta: </b>{transferencia.cuenta.tipo}</p>}
						<p><b>Número de Cuenta: </b>{transferencia.cuenta.numeroCuenta}</p>
					</div>
				</div>
				<div className='itemCd2'>
					<label>Valores</label>
					<div>
						<p><b>Total a Pagar: </b>{CoinFrom(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado)}</p>
						<p><b>Costos Bancarios: </b>{CoinFrom(transferencia.transferencia.cargos)}</p>
						{table !== 'colombia' && <p><b>Impuesto 4x1000: </b>{FormatCop(transferencia.transferencia.cuatromil)}</p>}
						{table === 'colombia' && <p><b>Servicio: </b>{CoinFee(transferencia.transferencia.fee)}</p>}
						<p><b>Tasa de Cambio: </b>{FormatCop(transferencia.transferencia.tasa)}</p>
						{table !== 'colombia' && <p><b>Servicio: </b>{CoinFee(transferencia.transferencia.fee)}</p>}
						{table === 'colombia' && <p><b>Impuesto 4x1000: </b>{FormatCop(transferencia.transferencia.cuatromil)}</p>}
						<p><b>Total a Recibir: </b>{CoinTo(transferencia.transferencia.to)}</p>
					</div>
				</div>
			</div>
		
			<ExportInvoice
				transferencia={transferencia}
				table={table}
				factura={false}
				id={id}
			/>

			{comprobante ? 
				<div className='card'>
					<div className="cardDocuments">
						<CardDoc documento={comprobante} label={'Comprobante'} trash={false} action={()=>{}} />
					</div>
				</div>
			:
				<Comprobante 
					dbTable={table}
					colcambios={transferencia.colcambios}
					fechaMaxPago={transferencia.fechaMaxPago}
					fechaTransfer={transferencia.fecha}
					docRef={id}
					uidUsuario={transferencia.empresa ? transferencia.empresa : transferencia.usuario} 
					rteEmail={transferencia.remitente.email} 
					rteNombre={transferencia.remitente.nombre} 
					banco={transferencia.cuenta.banco}
					bsb={transferencia.cuenta.bsb}
					titular={transferencia.cuenta.titular}
					cuenta={transferencia.cuenta.numeroCuenta}
					total={transferencia.transferencia.from}
					emailAud={transferencia.transferencia.to} 
					emailTasa={transferencia.transferencia.tasa}
					razon={transferencia.razon} 
					metodo={transferencia.transferencia.metodo}
					empresa={transferencia.empresa ? true : false}
				/>
			}
	</div>
}

export default PagarTransfer;