import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../../context/AuthContext';
import { useTrans } from '../../../context/TransferContext';
import { useConfig } from '../../../context/ConfigsContext';
import { getUnixTime } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { useGetBusiness, useGetReceivers, useGetTrans } from '../../../hooks/useGetInfo';
import { BuscarPromo,  BuscarReferido } from './components/BuscarCodigo.js';
import { AddTransfer, AsignarCodigo} from '../../../data/ManageTransfer';
import { AgregaUnContacto, MasdeUna } from '../../error/components/Restringed.js';
import StudentData from '../billetera/components/StudentData.js';
import { SetReceiver } from '../../../components/forms/SetReceiver.js';
import SetColcambios from '../../../components/forms/SetColcambios.js';
import { Input } from '../../../components/inputs/Input';
import { BtnGeneral } from '../../../components/buttons/Boton';
import { ModalValidar } from '../../../components/modales/Modales';
import { PopUpValid, HuboUnError } from '../../../components/mensajes/MensajesClient';
import SVG from 'react-inlinesvg'
import Check from '../../../img/icons/tick-square.svg';
import ImgNext from '../../../img/icons/arrow-right-1.svg';
import ImgPrev from '../../../img/icons/arrow-left-1.svg';
import ImgWarning from '../../../img/icons/warning-2.svg';
import animation from '../../../img/animation.svg';
import co from '../../../img/icons/co.svg';
import au from '../../../img/icons/au.svg';
import nz from '../../../img/icons/nz.svg';
import eau from '../../../img/icons/eau.svg';
import '../../../styles/form.css';
import '../../../styles/newProcess.css';
import '../../../styles/calculator.css';
import '../../../styles/newForm.css';
import { CoinsMenu } from './components/CoinsMenu.js';
import Calculator from '../../../components/conversor/Calculator.js';

const NewTransfer = () => {

	const Navigate = useNavigate();
	const { infoUser } = useAuth();
	const { OpenModal, setProcesando, FormatAud, FormatCop, FormatNzd, FormatEau, toUp, dateEmail, sideFix, Capitalize} = useConfig();
	const { ammounts, setAmmounts, asesor, promo, coins } = useTrans();

	const [transMax] = useGetTrans(); //COMPROBAR SI HAY MÁS DE 3 REGISTRADAS

	//DATOS
	const fecha = new Date();
	const [paisS, setPais] = useState('');

	const [values, setValues] = useState();
	useEffect(() => {if(ammounts){setValues(ammounts); setPais(ammounts.country); setPaso0(false); setPaso1(true);}}, [ammounts]);
	const calculadora = useRef();

	const [razon, setRazon] = useState('');
	const [spnNRazon, setSpnNRazon] = useState(false);
	const [altNRazon, setAltNRazon] = useState('');
	const [colcambios, setColcambios] = useState();
	const childCol = useRef();

	const [student, setStudent] = useState()
	const studentRef = useRef();
  const [externo, setExterno] = useState(false);

	//REMITER INFO
  const [businessID, setBusinessID] = useState('');
	const [logo, setLogo] = useState('')
  const [business] = useGetBusiness();

	const [uidUsuario, setUidUsuario] = useState('');
	const [rteNombre, setRteNombre] = useState('');
	const [rteSdoNombre, setRteSdoNombre ] = useState('');
	const [rteApellido, setRteApellido] = useState('');
	const [rteSdoApellido, setRteSdoApellido ] = useState('');
	const [rol, setRol] = useState('');
	const [rteTipoId, setRteTipoId] = useState('');
	const [rteNumeroId, setRteNumeroId] = useState('');
	const [rteEmail, setRteEmail] = useState('');
	const [rteCelular, setRteCelular] = useState('');
	const [rtePais, setRtePais] = useState('');
	const [rteCiudad, setRteCiudad] = useState('');
	const [rteAddress, setRteAddress] = useState('');

	//DESTINATARIO
	const [receivers] = useGetReceivers();
	const [destinos, setDestinos] = useState(true);
	const [receptor, setReceptor] = useState();
	const receiver = useRef();
	
	//CODIGOS
	const [referido, setReferido] = useState('');
	const [autoRef, setAutoRef] = useState('');

	const [codigoPromo, setCodigoPromo] = useState();
	//MONEDAS
	const FormatTo = (numero) =>{
		if(paisS === 'COP'){return FormatCop(numero)}
		if(paisS === 'AUD'){return FormatAud(numero)}
		if(paisS === 'NZD'){return FormatNzd(numero)}
    if(paisS === 'EAU'){return FormatEau(numero)}
	}
	
	const FormatFrom = (numero) =>{
		if(paisS === 'COP'){return FormatAud(numero)}
		if(paisS === 'AUD'){return FormatCop(numero)}
		if(paisS === 'NZD'){return FormatCop(numero)}
    if(paisS === 'EAU'){return FormatCop(numero)}
	}

	useEffect(()=>{
    if(infoUser){
			setUidUsuario(infoUser.usuario)
			setRol(infoUser.rol);
			if(infoUser.codRef){setAutoRef(infoUser.codRef)}
			if(infoUser.rol === 'empresa' || infoUser.rol === 'asesor'){
				if(business){
					setRteNombre(business.name)
					setRteSdoNombre('')
					setRteApellido('')
					setRteSdoApellido('')
					setRteTipoId(business.document.typeID)
					setRteNumeroId(business.document.numberID)
					const defaultAddress = Object.keys(business.address).map(key => 
						({...business.address[key], id: key})).filter(a => a.default)
					setRtePais(defaultAddress[0].country)
					setRteCiudad(defaultAddress[0].city)
					setRteAddress(defaultAddress[0].address)
					setRteEmail(business.contact.email)
					setRteCelular(business.contact.phones[0])
					setBusinessID(business.id)
					setLogo(business.logo)
				}
			}
			if(infoUser.rol === 'admin' || infoUser.rol === 'Usuario' || infoUser.rol === 'asistente'){
				setRteNombre(infoUser.nombre.nombre)
				setRteSdoNombre(infoUser.nombre.segNombre)
				setRteApellido(infoUser.nombre.apellido)
				setRteSdoApellido(infoUser.nombre.segApellido)
				setRteTipoId(infoUser.documento.tipoDocumento)
				setRteNumeroId(infoUser.documento.numeroDocumento)
				setRtePais(infoUser.direccion.pais)
				setRteCiudad(infoUser.direccion.ciudad)
				setRteAddress(infoUser.direccion.direccion)
				setRteEmail(infoUser.email)
				setRteCelular(infoUser.celular)
			}
    }
  },[infoUser, business])

	//COMPROBAR SI HAY DESTINATARIOS
	useEffect(()=>{
		if(infoUser){
			if(infoUser.rol === 'asesor'){
				if(receivers.length === 0){setDestinos(false);}
				else{setDestinos(true);}
			}else{
				let cuentas = []
				if(infoUser.cuentas){
					const results = Object.keys(infoUser.cuentas).map(key => 
						({...infoUser.cuentas[key], indice: key}))
					cuentas = results
				}
				if(receivers.length === 0 && cuentas.length === 0){setDestinos(false);}
				else{setDestinos(true);}
			}
		}
	},[infoUser, receivers])

  //FORMULARIO
	const [paso0, setPaso0] = useState(true)
	const [paso1, setPaso1] = useState(false);
	const [paso2, setPaso2] = useState(false);
	const [paso3, setPaso3] = useState(false);

	const StepZero = (pais) =>{setPais(pais); toUp(); setPaso0(false); setPaso1(true);}

	const StepOne = () =>{
		const datas = calculadora.current.calcular();
		if (!datas){return}
    if (datas){setValues(datas)}
		toUp(); setPaso1(false); setPaso2(true);
	}

	const StepTwo = () =>{
		if (externo){
      const info = studentRef.current.validarInfo();
      if (!info){return}else{setStudent(info)}
    }
		if (!externo){
			if (!razon){setSpnNRazon(true); setAltNRazon('Por ingresa la razón del envío'); return;}
			if (razon.length < 4){setSpnNRazon(true); setAltNRazon('Debe contener más de 5 letras'); return;}
			if (razon.length > 25){setSpnNRazon(true); setAltNRazon('Debe contener menos de 25 caracteres'); return;}
		}
		const datas = receiver.current.validarDestino();
    if (!datas){return}
    if (datas){setReceptor(datas)}
		toUp(); setPaso2(false); setPaso3(true);
	}

	const BackStepZero = (e) =>{e.preventDefault();
		toUp();
		setPais(''); 
		setReceptor();
		setPaso0(true);
		setPaso1(false);
		setColcambios();
	}

	const BackStepOne = (e) =>{e.preventDefault(); toUp(); setReceptor(receiver.current.validarDestino()); setPaso1(true); setPaso2(false);}
	const BackStepTwo = (e) =>{e.preventDefault(); setColcambios(childCol.current.validarCuenta()); toUp(); setPaso2(true); setPaso3(false);}

  const Validar = (e) => {
		e.preventDefault();
		let cuenta
		const datas = childCol.current.validarCuenta();
		if (!datas){return}
		if (datas){cuenta = datas; setColcambios(datas)}
		
		OpenModal(
			<ModalValidar 
				accion={()=>Submit(cuenta)} 
				titulo={'Registrar Transferencia'}
				mensaje={'¿Deseas Registrar esta transferencia?'}
				botonName={'Sí, Registrar'}
				botonClass={'btnGeneral primaryC'}
		/>)
	}

	const Submit = (cuenta) =>{
		toUp();
		setProcesando(true);
		AddTransfer({
			table: values.bdName,
			data:{
				fecha: getUnixTime(fecha),
				fechaMaxPago: ((new Date().getTime()) + 900000),
				estado: 'Registrada',
				usuario: uidUsuario,
				...businessID && {empresa: businessID},
				destinatarioId: receptor.receiverID,
				razon: externo ? student.razon : razon,
				colcambios: cuenta,
				autoEnvio: receptor.receiverID === uidUsuario ? true : false,
				transferencia:{
					from: values.from,
					to: values.to,
					metodo: values.method,
					fee: values.fee,
					cargos: values.impuesto,
					tasa: values.tasa,
					cuatromil: values.cuatromil
				},
				remitente:{
					nombre: rteNombre,
					segNombre: rteSdoNombre,
					apellido: rteApellido,
					segApellido: rteSdoApellido,
					tipoId: rteTipoId,
					numeroId: rteNumeroId,
					email: rteEmail,
					celular: rteCelular,
					pais: rtePais,
					ciudad: rteCiudad,
					direccion: rteAddress
				},
				destinatario:{
					tipo: receptor.tipoDno,
					nombre: receptor.name,
					segNombre: receptor.segName,
					apellido: receptor.surName,
					segApellido: receptor.segSurName,
					tipoId: receptor.idType,
					numeroId: receptor.idNumber,
					documento: receptor.idDoc,
					email: receptor.email,
					celular: receptor.phone,
					pais: receptor.country,
					ciudad: receptor.city || '',
					direccion: receptor.address
				},
				cuenta:{
					banco: receptor.bank,
					titular: receptor.accountName,
					numeroCuenta: receptor.accountNumber,
					...receptor.bsb && {bsb: receptor.bsb},
					...receptor.tipoCuenta && {tipo: receptor.tipoCuenta},
					...receptor.id && {id: receptor.id},
          ...receptor.iban && {iban: receptor.iban}
				},
				...referido && {referido: referido},
				...codigoPromo && {promociones:{
					codigo: codigoPromo.id,
					tipo: codigoPromo.tipo,
					descuento: codigoPromo.descuento,
					...codigoPromo.agenciaId && {agenciaId: codigoPromo.agenciaId},
				}},
				...externo && {cliente: student},
        ...logo && {logo: logo}
			}
		}).then(async(docRef) => {
			const datos = {
				id: docRef.id.substring(0, 6).toUpperCase(),
				fecha: dateEmail(getUnixTime(fecha)).toUpperCase(),
				estado: 'Registrada',
				nombre: Capitalize(rteNombre),
				email: rteEmail,
				metodo: values.method,
				tasa: FormatCop(values.tasa),
				aud: FormatTo(values.to),
				cop: FormatFrom(values.from),
				razon: externo ? student.razon : razon,
				titular: Capitalize(receptor.accountName),
				bank: receptor.bank,
				bsb: receptor.bsb,
				account: receptor.accountNumber,
				msj: 'fue registrada con éxito. A continuación encontrarás la información e instrucciones de pago'
			} 

			if(codigoPromo){
				if(codigoPromo.tipo === 'Promocional'){
					AsignarCodigo({
						usuario: uidUsuario,
						codigo: codigoPromo.id
					})
				}
			}

			await axios.post('https://server-colcambios.herokuapp.com/api/transferencia',{datos})
				.then(res =>{
					if(res.data.success){
						let pais = values.bdName
						if(paisS === 'AUD'){pais = 'australia'}
            if(paisS === 'EAU'){pais = 'emiratosArabesUnidos'}
						setProcesando(false);
						OpenModal(<PopUpValid mensaje={'La transferencia se ha registrado Correctamente'}/>);
						Navigate(`/pagar-transferencia/${pais}/${docRef.id}`)
						setAmmounts();
						if(asesor){localStorage.removeItem('asesor');}
						if(promo){localStorage.removeItem('promo');}
					}else{
						setProcesando(false);
						OpenModal(<HuboUnError/>);
					}
				}).catch(()=>{
					setProcesando(false);
					OpenModal(<HuboUnError/>);
				})
		}).catch(()=>{
			setProcesando(false);
			OpenModal(<HuboUnError/>);
		})
	}

	return (
		<div className='dashPage'>
			<Helmet><title>Nueva Transferencia</title></Helmet>
		
			<p className="pageTitle">Nueva Transferencia</p>

			{!destinos ? <AgregaUnContacto /> : transMax ? <MasdeUna /> :
			<div className={'windowProcess'}>
				<form className={sideFix ? 'windowFormRowX' : 'windowForm windowLarge' }>
					<div className='windowImagen'><SVG src={animation}/></div>

					<div className='windowContent'>
						{paso0 && <CoinsMenu action={StepZero} coins={coins} />}

						{!paso0 && <div className='cardsIndicator'>
							<div className='cardCountry'>
								{paisS === 'AUD' && <><SVG src={co} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={au} /><span>Envías De <b>Colombia</b> a <b>Australia</b></span></>}
								{paisS === 'NZD' && <><SVG src={co} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={nz} /><span>Envías De <b>Colombia</b> a <b>Nueva Zelanda</b></span></>}
								{paisS === 'EAU' && <><SVG src={co} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={eau} /><span>Envías De <b>Emiratos Árabes Unidos</b> a <b>Colombia</b></span></>}
								{paisS === 'COP' && <><SVG src={au} /><SVG src={ImgNext} className='arrowFlag' /><SVG src={co} /><span>Envías De <b>Australia</b> a <b>Colombia</b></span></>}
							</div>
							{!paso1 &&
								<div className='cardAmmount'>
									<p><span>Pagas</span>{FormatFrom(values.from)}</p>
									<p><span>Reciben</span>{FormatTo(values.to)}</p>
								</div>
							}
						</div>}

						{paso1 && <>
							{/* <div className="Recomendacion">
								<div><SVG src={ImgWarning} /></div>
								<p>Por motivo del feriado de Semana Santa los bancos en Colombia no prestan su servicio cómo lo hacen habitualmente 
									y esto también puede afectar nuestros tiempos de servicio. Es por esto que<b> las transferencias que se realicen después del miércoles 27 
									de Marzo se estarán procesando entre el 1 y 2 de Abril de 2024.</b></p>
							</div> */}
							<Calculator ref={calculadora} values={values} pais={paisS} codigoPromo={codigoPromo} />

							<BuscarPromo usuario={uidUsuario} setCodigo={setCodigoPromo} check={codigoPromo}/>	

							<div className='btnsCase'>
								<BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
									type="button" action={BackStepZero} clases={'primaryC'} large={false} />
								<BtnGeneral text={'Siguiente'} img={ImgNext} side={'R'} disabled={false}
									type="button" action={StepOne} clases={'primaryC'} large={true} />
							</div>
						</>}

						{paso2 && <>
							{business && externo ? <StudentData ref={studentRef} setShow={setExterno} student={student} /> : 
								rol !== 'Usuario' &&
								<BtnGeneral text={'¿Es una factura para un Cliente?'} img={''} side={'L'} disabled={false} type="button" 
									action={()=>{setExterno(true);}} clases={'primaryC'} large={true} />
							}

							{!externo &&
							<Input name={'nuevaRazon'} type={'text'} label={'Razón del Envío'} value={razon} setValue={setRazon} 
								alt={altNRazon} spn={spnNRazon} setSpn={setSpnNRazon} fill={'on'} />}
							
							<div className="Recomendacion">
								<div><SVG src={ImgWarning} /></div>
								<div><p>Para no tener retrasos con tu transferencia, por favor ingresa la razón de manera <b>específica</b>. 
									Ejemplo: Renta, Pago de Estudios, Apoyo familiar o Compra de vehículo. (preferiblemente en inglés).</p></div>
							</div>

							<SetReceiver ref={receiver} uid={uidUsuario} receptor={receptor} receivers={receivers} country={paisS} />

							<div className='btnsCase'>
								<BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
									type="button" action={BackStepOne} clases={'primaryC'} large={false} />
								<BtnGeneral text='Siguiente' img={ImgNext} side={'R'} disabled={false}
									type="button" action={StepTwo} clases={'primaryC'} large={true} />
							</div>
						</>}
							
						{paso3 && <>
							<SetColcambios ref={childCol} colcambios={colcambios} country={paisS} />
							<div className="Recomendacion">
								<div><SVG src={ImgWarning}/></div>
								<div>
									<span>Importante:</span>
									<p>Para evitar contratiempos con el método <b>UltraRápido</b>, es indispensable que selecciones y 
									realices el pago de tu transferencia entre cuentas del mismo banco o en efectivo.</p>
								</div>
							</div>
							{paisS === 'AUD' && <BuscarReferido setReferido={setReferido} autoRef={autoRef} rol={rol} />}
							
							<div className='btnsCase'>
								<BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
									type="button" action={BackStepTwo} clases={'primaryC'} large={false} />
								<BtnGeneral text='Confirmar' img={Check} side={'R'} disabled={false}
									type="submit" action={Validar} clases={'primaryC'} large={true} />
							</div>
						</>}

					</div>
				</form>
			</div>
			}
		</div>
	)
}

export default NewTransfer;