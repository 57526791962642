import React, { useEffect, useState } from 'react';
import { SelectorChoose } from '../../../components/inputs/Selector';
import { BtnGeneral } from '../../../components/buttons/Boton';
import Check from '../../../img/icons/tick-square.svg';
import { zonedTimeToUtc } from 'date-fns-tz';
import { getUnixTime } from 'date-fns';
import { useResults } from '../../hooks/useReports';
import { AgregarDatos } from '../../data/AdmintReports';
import { useConfig } from '../../../context/ConfigsContext';
import { HuboUnError, PopUpValid } from '../../../components/mensajes/MensajesClient';
import { ModalValidar } from '../../../components/modales/Modales';
import Ventas from './components/Ventas';
 
const InformesGenerales = () => {

  const {setProcesando, OpenModal, FormatAud, FormatCop} = useConfig();
  const [busqueda, setBusqueda] = useState();

  const [mes, setMes] = useState('');
  const [mesID, setMesID] = useState('');
  const [year, setYear] = useState('')
  const [yearID, setYearID] = useState('')


  const [spnMes, setSpnMes] = useState(false)
  const [spnYear, setSpnYear] = useState(false)
  const [altMes, setAltMes] = useState('')
  const [altYear, setAltYear] = useState('')

  const years = [{id: '2022', text: '2022'}, {id: '2023', text: '2023'},  {id: '2024', text: '2024'}, {id: '2025', text: '2025'}]
  const meses = [
    {id: '01', text: 'Enero'},
    {id: '02', text: 'Febrero'},
    {id: '03', text: 'Marzo'},
    {id: '04', text: 'Abril'},
    {id: '05', text: 'Mayo'},
    {id: '06', text: 'Junio'},
    {id: '07', text: 'Julio'},
    {id: '08', text: 'Agosto'},
    {id: '09', text: 'Septiembre'},
    {id: '10', text: 'Octubre'},
    {id: '11', text: 'Noviembre'},
    {id: '12', text: 'Diciembre'},
  ]

  const { aus, cop, userC} = useResults(busqueda);

  const [sales, setSales] = useState('');
  const [buys, setBuys] = useState('');
  const [fees, setFees] = useState('');
  const [tasa, setTasa] = useState('');
  const [numerAu, setNumerAu] = useState('');
  const [numerCo, setNumerCo] = useState('');
  const [numerUser, setNumerUser] = useState('');
 
  useEffect(() => {
    if(aus && cop){
      setSales(aus.sales)
      setBuys(cop.sales)
      setFees(aus.fees + cop.fees)
      setTasa(aus.tasa)
      setNumerAu(aus.numero)
      setNumerCo(cop.numero)
      setNumerUser(userC)
    }
  }, [aus, userC, cop]);

  const timeZone = 'Australia/Canberra'
  const SetInit = (fecha) =>{
    const init = zonedTimeToUtc((fecha+ 'T00:00:00'), timeZone)
    return getUnixTime(init)
  }

  const SetEnd = (fecha) =>{
    const end = zonedTimeToUtc((fecha+ 'T23:59:59'), timeZone)
    const mesUnoL = zonedTimeToUtc(new Date(end.getFullYear(), end.getMonth()+1, 1), timeZone);
    return getUnixTime(mesUnoL)
  }

  const Buscar = (e) =>{
    e.preventDefault();
    if(!mes){setSpnMes(true); setAltMes('Selecciona'); return}
    if(!year){setSpnYear(true); setAltYear('Selecciona')}

    const date = `${yearID}-${mesID}-01`

    const inicial = SetInit(date)
    const final = SetEnd(date)

    setBusqueda({
      ini: inicial,
      end: final
    })
  }

  const Validar = (e) =>{
    e.preventDefault();
    OpenModal(
			<ModalValidar 
				accion={CapturarDatos} 
				titulo={'Registrar Resultados'}
				mensaje={'¿Deseas Registrar estos resultados?'}
				botonName={'Sí, Registrar'}
				botonClass={'btnGeneral primaryC'}
		/>)
  }

  const CapturarDatos = () =>{
    setProcesando(true);
    AgregarDatos({
      datos:{
        buys: buys,
        buysNumber: numerCo,
        fees: fees,
        sales: sales,
        salesNumber: numerAu,
        tasa: tasa ? tasa : 0,
        users: numerUser,
        iniDate: busqueda.ini,
        endDate: busqueda.end,
        mes: mes,
        year: year,
      }
    }).then(()=>{
      setProcesando(false);
      OpenModal(<PopUpValid mensaje={'El informe se ha registrado Correctamente'}/>);
      setBusqueda()
    }).catch((error)=>{
      setProcesando(false);
      OpenModal(<HuboUnError/>);
    })
  }

  return (
    <>
      <Ventas />
      <div className='card'>
        <p className='cardTitle'>Buscar informes</p>
        <SelectorChoose action={setMesID} label={'Mes'} value={mes} setValue={setMes} 
          options={meses} alt={altMes} spn={spnMes} setSpn={setSpnMes} />
        <SelectorChoose action={setYearID} label={'Año'} value={year} setValue={setYear} 
          options={years} alt={altYear} spn={spnYear} setSpn={setSpnYear} />
        <BtnGeneral text='Buscar' img={Check} side={'R'} disabled={false}
          type="submit" action={Buscar} clases={'primaryC'} large={true} />
      </div>
      {aus &&
        <div className='card'>
          <p className='cardTitle'>Resultados</p>
          <div className="cardData">
            <div><p><label>Ventas</label>{FormatAud(sales)}</p></div>
            <div><p><label>No. de Ventas</label>{numerAu}</p></div>
            <div><p><label>Compras</label>{FormatAud(buys)}</p></div>
            <div><p><label>No. de Compras</label>{numerCo}</p></div>
            <div><p><label>Servicio</label>{FormatAud(fees)}</p></div>
            <div><p><label>Tasa</label>{tasa ? FormatCop(tasa) : FormatCop(0)}</p></div>
            <div><p><label>Usuarios</label>{numerUser}</p></div>
          </div>
          <BtnGeneral text='Guardar' img={Check} side={'R'} disabled={false}
            type="submit" action={Validar} clases={'primaryC'} large={true} />
        </div>
      }

      
    </>
  );
}
 
export default InformesGenerales;