import React, { useState, forwardRef, useImperativeHandle, useEffect } from "react";
import { useConfig } from "../../context/ConfigsContext";
import { NumericFormat } from "react-number-format";
import './Input.css';
import {ReactComponent as Show} from '../../img/icons/eye.svg';
import {ReactComponent as Shown} from '../../img/icons/eye-slash.svg';
import SVG from 'react-inlinesvg';
import ImgCalendar from '../../img/icons/calendar.svg';
import Co from '../../img/icons/co.svg';
import Au from '../../img/icons/au.svg'; 
import Nz from '../../img/icons/nz.svg'; 
import Check from '../../img/icons/check.svg';
import Remove from '../../img/icons/ticket.svg';

const InputDisabled  = (props) =>{
	const{
		label,
		value,
		setValue
	}= props;

	const handleChange = (e) =>{setValue(e.target.value);}
	return(
		<div className={value ? 'inputDisabled' : 'inputDisabled'}>
			<input name={'inputDisable'} type={'text'} placeholder=" " value={value} disabled={true} id="name" onChange={handleChange} />
			<label htmlFor="name">{label}</label>
		</div>
	)
}

const InputDisabledCop  = (props) =>{
	const{
		label,
		value
	}= props;

	const {FormatCop} = useConfig();

	return(
		<div className='inputDisabled'>
			<input name={'inputDisable'} type={'text'} placeholder=" " value={FormatCop(value)} disabled={true} id="name" />
			<label htmlFor="name">{label}</label>
		</div>
	)
}

const Input = (props) =>{
	const{
		type,
		label,
		value,
		setValue,
		alt,
		spn,
		setSpn,
		fill
	}= props;

	const handleChange = (e) =>{ setValue(e.target.value); setSpn(false); }

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<input name='input' type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} id="name" />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputSearch = (props) =>{
	const{
		type,
		label,
		value,
		setValue,
		alt,
		spn,
		setSpn,
		fill,
		options
	}= props;

	const [show, setShow] = useState(false);
	const [filtro, setFiltro] = useState('');
	const handleClick = (e) => {setShow(false); setValue(e.currentTarget.dataset.id);}
	const clickInput = (e) =>{e.preventDefault(); setShow(false);}
	const handleChange = (e) =>{setShow(true); setFiltro(e.target.value); setValue(e.target.value); setSpn(false)}
	const handOVer = (e) =>{e.preventDefault(); setShow(false);}

	const Filtradas = (options) =>{
		const resultados = options.filter(code => {
			return code.texto.toLowerCase().includes(filtro.toLowerCase());
		});

		const nuevos = resultados.map((code)=>{
			return(
				<div key={code.id} className="searched" onClick={handleClick} data-id={code.texto}>
					<span>{code.texto}</span>
				</div>
			)
		})
		return nuevos;
	}

	return(
		<div className={!spn ? 'input': 'input redLine'} onMouseLeave={handOVer}>
			<input name='input' type={type} placeholder=" " value={value} onChange={handleChange} onClick={clickInput}
				autoComplete={fill} id="name" className={(show ? 'BorderSelec' : '')+' '+(value!=='' ? 'BorderBlue':'' )} />
			<label htmlFor="name">{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
			{show && <div className="searchedDiv">{filtro && Filtradas(options)}</div>}
		</div>
	);
}

const InputOptional = (props) =>{
	const{
		type,
		label,
		value,
		setValue,
		fill,
		name,
	}= props;

	const handleChange = (e) =>{ setValue(e.target.value);}

	return(
		<div className='input'>
			<input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
			<label>{label}</label>
		</div>
	)
}

const InputIco = (props) =>{

	const{
		name,
		type,
		label,
		value,
		setValue,
		Img,
		alt,
		spn,
		setSpn,
		fill
	}= props;

	const handleChange = (e) =>{ setValue(e.target.value); setSpn(false); }

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
			<SVG src={Img} />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputIcoOp = (props) =>{

	const{
		name,
		type,
		label,
		value,
		setValue,
		Img,
		fill
	}= props;

	const handleChange = (e) =>{ setValue(e.target.value); }

	return(
		<div className='input inputIco'>
			<input name={name} type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
			<label htmlFor="name">{label}</label>
			<SVG src={Img} />
		</div>
	)
}

const InputPass = (props) =>{

    const {
			name,
			label,
			value,
			setValue,
			alt,
			spn,
			setSpn,
			fill 
    }= props;

    const [show, setShow] = useState(false);
    const handleShow = () =>{ setShow(!show); }

    const handleChange = (e) =>{setValue(e.target.value); setSpn(false);}

    return(
        <div className={!spn ? 'input': 'input redLine'}>
            <input name={name} className={!show ? 'dotsPass':' '} type={show ? 'text' : 'password'} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
            <label>{label}</label>
            {spn && <span className="spanAlert">{alt}</span>}
            <button className='inputIcon' onClick={handleShow} type="button">{show ? <Shown /> : <Show />}</button>
        </div>
    )
}

const InputPhone = forwardRef((props, ref) => {

	useImperativeHandle(ref, ()=>{return {validarPhone: Validate}})

	const{
		label,
		value,
		setValue,
		cod,
		spn,
		setSpn,
		alt,
		setAlt
	} = props

	const [code, setCode] = useState('+61');
	const [show, setShow] = useState(false)
  const [codeImg, setCodeImg] = useState(Au);
    
	const handleClick = (e) => {setShow(false); setCode(e.currentTarget.dataset.id);setCodeImg(e.currentTarget.dataset.img); setSpn(false)}
  const clickInput = (e) =>{e.preventDefault(); setShow(false);}
  const handleChangeCode = (e) =>{e.preventDefault();setShow(true);}
  const handleChange = (e) =>{setValue(e.target.value); setSpn(false)}
  const handOVer = (e) =>{e.preventDefault();setShow(false);}

	const codes = [
		{ id: '1', img:Co, text: '+57' },
		{ id: '2', img:Au, text: '+61'},
		{ id: '3', img:Nz, text: '+64'}
	]

	useEffect(() => {
		if(cod){
			setCode(cod)
			if(cod === '+57'){setCodeImg(Co)}
			if(cod === '+61'){setCodeImg(Au)}
		}
	}, [cod]);

	const Validate = () =>{
		let numero = ''
		if(code === '+61'){
			if(value){
				if(value.length < 9 || value.length > 9 ){
					setSpn(true); setAlt('Debe de tener 9 dígitos');
				}else{
					numero = code+' '+value
					return numero
				}
			}
		}if(code === '+57'){
			if(value){
				if(value.length < 10 || value.length > 10 ){
					setSpn(true); setAlt('Debe de tener 10 dígitos');
				}else{
					numero = code+' '+value
					return numero
				}
			}
		}
	}

	const dial = codes.map((code)=>{
		return(
			<div key={code.id} className="selectCode" onClick={handleClick} data-id={code.text} data-img={code.img}>
				<img src={code.img} alt="country" /><span>{code.text}</span>
			</div>
		)
	})

	return(
		<div className={!spn ? 'inputPhone': 'inputPhone redLine'} onMouseLeave={handOVer}>
			<div className="inputCode">
				<button onClick={handleChangeCode}><img src={codeImg} alt="country"/>{code}</button>
				<div className={show ? 'selectCodes' : 'selectCodesnull'} onMouseLeave={handOVer}>{dial}</div>
			</div>
			<div className='inputNumber'>
				<input type="number" name="phone" placeholder=" " value={value} onChange={handleChange} onClick={clickInput}
					className={(show ? 'BorderSelec' : '')+' '+(value!=='' ? 'BorderBlue':'' )} autoComplete="off"/>
				<label>{label}</label>
				{spn && <span className="spanAlert">{alt}</span>}
			</div>
		</div>
	)
})

const InputDate = (props) =>{
	const{
		label,
		value,
		setValue,
		spn,
		alt,
		setSpn,
		fill
	} = props;


	const handleChange = (e) =>{ 
		setValue(e.target.value); 
		setSpn(false);
	}
	
	return(
		<div className={(!spn ? 'input inputDate': 'input inputDate redLine') + (value ? '' : ' inputNoData')}>
			<SVG src={ImgCalendar} />
			<input type="date" placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputButton = (props) =>{

	const{
		label,
		value,
		setValue,
		type,
		spn,
		setSpn,
		alt,
		fill,
		action,
		actionName,
		clases
	} = props;

	const handleChange = (e) =>{setValue(e.target.value);setSpn(false)}

	return(
		<div className={'inputButton inputButtonNormal '+ (!spn ? '': 'redLine')}>
			<input name="value" type={type} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
			<button className={clases} onClick={action} type="button">{actionName}</button>
		</div>
	)
}

const InputCop = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		setFormated,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
		setFormated(values.formattedValue);
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'input': 'input redLine' }>
			<NumericFormat value={value} thousandSeparator={'.'} onValueChange={handleChange} disabled={disabled}
				decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} autoComplete={fill}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputCO = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
		setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={(!spn ? 'input': 'input redLine') + (!value || value === '0' ? ' inputNoData' : '')}>
			<NumericFormat value={value} thousandSeparator={'.'} onValueChange={handleChange} disabled={disabled}
				decimalSeparator={','} prefix={'$'} suffix={' COP'} decimalScale={'2'} autoComplete={fill} defaultValue={0} />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputUS = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={(!spn ? 'input': 'input redLine') + (value ? '' : ' inputNoData')}>
			<NumericFormat value={value} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'2'} 
        prefix={'$'} suffix={' USD'} disabled={disabled} onValueChange={handleChange} autoComplete={fill}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputAU = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={(!spn ? 'input': 'input redLine') + (!value || value === '0' ? ' inputNoData' : '')}>
			<NumericFormat value={value} thousandSeparator={','} onValueChange={handleChange} disabled={disabled}  
        decimalSeparator={'.'} prefix={'$'} suffix={' AUD'}  decimalScale={'2'} autoComplete={fill} defaultValue={0}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputNZ = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={(!spn ? 'input': 'input redLine') + (!value || value === '0' ? ' inputNoData' : '')}>
			<NumericFormat value={value} thousandSeparator={','} onValueChange={handleChange} disabled={disabled}  
        decimalSeparator={'.'} prefix={'$'} suffix={' NZD'}  decimalScale={'2'} autoComplete={fill} defaultValue={0}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputAED = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={(!spn ? 'input': 'input redLine') + (!value || value === '0' ? ' inputNoData' : '')}>
			<NumericFormat value={value} thousandSeparator={','} onValueChange={handleChange} disabled={disabled}  
        decimalSeparator={'.'} prefix={'$'} suffix={' AED'}  decimalScale={'2'} autoComplete={fill} defaultValue={0}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputAud = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		setFormated,
		fill,
		disabled
	} = props;

	const handleChange = (values) =>{
		setFormated(values.formattedValue);
    setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<NumericFormat value={value} thousandSeparator={','} decimalSeparator={'.'} decimalScale={'2'} 
        prefix={'$'} suffix={' AUD'} disabled={disabled} onValueChange={handleChange} autoComplete={fill}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputText = (props) =>{

	const{
		label,
		value,
		setValue,
		spn,
		setSpn,
		alt,
		disabled
	} = props;

	const handleChange = (e) =>{setValue(e.target.value);setSpn(false);}

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<textarea type="text" value={value} placeholder=" " id="textAreaContact" onChange={handleChange} disabled={disabled} />
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputPorcent = (props) =>{

	const{
		label,
		spn,
		setSpn,
		alt,
		value,
		setValue,
		disabled
	} = props;

	const handlePorcentaje = (values) => {
			setValue(values.floatValue);
			setSpn(false);
    }

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<NumericFormat value={value} suffix={'%'} decimalSeparator={'.'} onValueChange={handlePorcentaje} disabled={disabled}/>
			<label>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
		</div>
	)
}

const InputPorcentTable = (props) =>{

	const{
		spn,
		setSpn,
		value,
		setValue,
		disabled,
		prefix
	} = props;

	const handlePorcentaje = (values) => {
		setValue(values.floatValue);
		setSpn(false);
	}

	return(
		<div className={!spn ? 'input': 'input redLine'}>
			<NumericFormat value={value} prefix={prefix} suffix={'%'} decimalSeparator={'.'} onValueChange={handlePorcentaje} disabled={disabled} allowNegative={false}/>
		</div>
	)
}

const InputButtonVal = (props) =>{

	const{
		label,
		value,
		setValue,
		fill,
		name,
		spn,
		setSpn,
		alt,
		side,
		validate,
		action,
		text,
		img,
		disabled,
		clases
	} = props;

	const handleChange = (e) =>{setValue(e.target.value);setSpn(false);}

	return(
		<div className={'inputButton '+ (!spn ? '': 'redLine')}>
			<input name={name} type={'text'} placeholder=" " value={value} onChange={handleChange} autoComplete={fill} id={name}/>
			<label htmlFor={name}>{label}</label>
			{spn && <span className="spanAlert">{alt}</span>}
			{validate ? <SVG src={Check} className="ValueCheck"/> : <SVG src={Remove} className="ValueNone" />}
			<button className={clases} type='button' disabled={disabled} onClick={action}>
				{side==='L' && img && <SVG src={img} />}{text}{side==='R' && img && <SVG src={img} />}
			</button>
		</div>
	)
}

export{
	Input,
	InputSearch,
	InputOptional,
	InputIco,
	InputIcoOp,
	InputPhone,
	InputPass,
	InputDisabled,
	InputDisabledCop,
	InputDate,
	InputButton,
	InputCop,
	InputUS,
  InputCO,
	InputAED,
	InputAud,
	InputAU,
	InputNZ,
	InputText,
  InputPorcent,
	InputPorcentTable,
	InputButtonVal
}