import React from 'react'
import { useTrans } from '../../../../context/TransferContext';
import { useConfig } from '../../../../context/ConfigsContext';
import Toggle from '../../../../components/inputs/Toggle';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { EstadoMoneda } from '../../../data/AdminRate';
import SVG from 'react-inlinesvg';
import co from '../../../../img/icons/co.svg';
import au from '../../../../img/icons/au.svg';
import nz from '../../../../img/icons/nz.svg';
import eau from '../../../../img/icons/eau.svg';

const CoinRoutes = props => {

  const {setProcesando, OpenModal} = useConfig();
  const {rutas} = useTrans();

  const AccionEstado = (estado, id, field) =>{
    setProcesando(true)
    const valor = estado ? false : true
    EstadoMoneda({status: valor, id: id, field: field})
    .then(async()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={`El servicio gratis ha sido ${valor ? 'Activado' : 'Desactivado'}`} />)
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />)
    })
  }

  return (
    <div className='card'>
      <p className="cardTitle">Monedas</p>
      {rutas.length > 0 && rutas.map((ruta ,i)=>{

        let image
        if(ruta.short === 'COP'){image = co}
        if(ruta.short === 'AUD'){image = au}
        if(ruta.short === 'NZD'){image = nz}
        if(ruta.short === 'AED'){image = eau}

        return (
        <div className="cardFlex3Div" key={i}>
          <p className="cardTitle">{ruta.text} <SVG src={image} /></p>
          <div className="itemConfig">
            <Toggle estado={ruta.from} accion={()=>AccionEstado(ruta.from, ruta.id, 'from')}/>
            <p>Origen</p>
          </div>
          <div className="itemConfig">
            <Toggle estado={ruta.to} accion={()=>AccionEstado(ruta.to, ruta.id, 'to')}/>
            <p>Destino</p>
          </div>
          <div className="itemConfig">
            <Toggle estado={ruta.fast} accion={()=>AccionEstado(ruta.fast, ruta.id, 'fast')}/>
            <p>UltraRápido</p>
          </div>
        </div>
        )
      })}
    </div>
  )
}

export default CoinRoutes
