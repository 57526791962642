import React, { useEffect, useImperativeHandle, useState } from 'react';
import { SelectAndInput, Selector } from '../inputs/Selector';
import { Input } from '../inputs/Input';
import NAB from '../../img/icons/nab.png';
import ANZ from '../../img/icons/anz.png';
import COMMON from '../../img/icons/commonbank.png';
import BNZ from '../../img/icons/bnz.png';
import KIWI from '../../img/icons/kiwi.png';
import BBIA from '../../img/icons/bancolombia.jpg';
import DV from '../../img/icons/davivienda.jpg';
import BTA from '../../img/icons/bogota.jpg';
import NBD from '../../img/icons/NBD.jpg';
import ADCB from '../../img/icons/ADCB.jpg';
import CBD from '../../img/icons/CBD.jpg';
import FAB from '../../img/icons/FAB.jpg';
import { forwardRef } from 'react';

const SetBank = forwardRef((props, ref) => {
 
  useImperativeHandle(ref, ()=>{return {validarCuenta: Validate}})
  const {country} = props;
  const tranSpaces = (nombre) =>{ const id = nombre.replace(/\s+/g, ''); return id;}

  const [banco, setBanco] = useState('');
  const [oBanco, setOBanco] = useState('');
  const [tipoCuenta, setTipoCuenta] = useState('');
  const [titular, setTitular] = useState('');
  const [bsb, setBsb] = useState('');
  const [bsb2, setBsb2] = useState('');
  const [numeroCuenta, setNumeroCuenta] = useState('');
  const [numeroCuenta2, setNumeroCuenta2] = useState('');
  const [iban, setIban] = useState('');
  const [iban2, setIban2] = useState('');

  const [spnNBanco, setSpnNBanco] = useState(false);
  const [spnOBanco, setSpnOBanco] = useState(false);
  const [spnTipo, setSpnTipo] = useState(false);
  const [spnIban, setSpnIban] = useState(false);
  const [spnIban2, setSpnIban2] = useState(false);
  const [spnTitular, setSpnTitular] = useState(false);
  const [spnBsb, setSpnBsb] = useState(false);
  const [spnBsb2, setSpnBsb2] = useState(false);
  const [spnNCuenta, setSpnNCuenta] = useState(false);
  const [spnNCuenta2, setSpnNCuenta2] = useState(false);

  const [altNBanco, setAltNBanco] = useState('');
  const [altOBanco, setAltOBanco] = useState('');
  const [altTipo, setAltTipo] = useState('');
  const [altIban, setAltIban] = useState('');
  const [altIban2, setAltIban2] = useState('');
  const [altTitular, setAltTitular] = useState('');
  const [altBsb, setAltBsb] = useState('');
  const [altBsb2, setAltBsb2] = useState('');
  const [altNCuenta, setAltNCuenta] = useState('');
  const [altNCuenta2, setAltNCuenta2] = useState('');

  const [otro, setOtro] = useState(false);
  useEffect(()=>{if(banco === 'Otro'){setOtro(true);}else{setOtro(false)}},[banco])

  const [banks, setBanks] = useState([]);

  useEffect(()=>{
    const banksShort = [
      {id:"1", text: "Commonwealth Bank", img: COMMON},
      {id:"2", text: "ANZ", img: ANZ},
      {id:"3", text: "NAB", img: NAB},
      {id:"4", text: "Otro"}
    ]
  
    const banksColombia = [
      {id:"1", text: "Bancolombia", img: BBIA},
      {id:"2", text: "Davivienda", img: DV},
      {id:"3", text: "Banco de Bogotá", img: BTA},
      {id:"4", text: "Otro"}
    ]
  
    const banksNzd = [
      {id:"1", text: "Bank of New Zealand", img: BNZ},
      {id:"2", text: "KiwiBank", img: KIWI},
      {id:"3", text: "ANZ", img: ANZ},
      {id:"4", text: "Otro"}
    ]
  
    const banksEau = [
      {id:"1", text: "Emirates NBD", img: NBD},
      {id:"2", text: "Abu Dhabi Commercial Bank", img: ADCB},
      {id:"3", text: "Commercial Bank of Dubai", img: CBD},
      {id:"4", text: "First Abu Dhabi Bank", img: FAB},
      {id:"5", text: "Otro"}
    ]

    if(country === 'Australia'){setBanks(banksShort)}
    if(country === 'Colombia'){setBanks(banksColombia)}
    if(country === 'EAU'){setBanks(banksEau)}
    if(country === 'Nueva Zelanda'){setBanks(banksNzd)}
  },[country])

  const tiposCuenta = [
    {id:"1", text: "Cuenta de Ahorros"}
  ]

  const Validate = () =>{
    let info
    if (!banco) {setSpnNBanco(true); setAltNBanco('Selecciona'); return;}
    if (banco === 'Otro') {if(!oBanco){setSpnOBanco(true); setAltOBanco('Ingresa el Banco'); return;}}
    if (!titular) {setSpnTitular(true); setAltTitular('Ingresa el Titular'); return;}
    if (country === 'Colombia'){
      if(!tipoCuenta){setSpnTipo(true); setAltTipo('Selecciona'); return}
      if(!documento){setSpnDocumento(true); setAltDocumento('Ingresa el Número'); return;}
    }

    if (country === 'Australia'){
      if (!bsb){setSpnBsb(true); setAltBsb('Ingresa el número BSB'); return;}
      if (bsb.length !== 6){setSpnBsb(true); setAltBsb('El número debe ser de 6 dígitos'); return;}
      if (!bsb2){setSpnBsb2(true); setAltBsb2('Confirma el número BSB'); return;}
      if (bsb2 !== bsb){setSpnBsb2(true); setAltBsb2('El número BSB no es igual'); return;}
    }

    if (country === 'EAU'){
      if (!iban){setSpnIban(true); setAltIban('Ingresa el IBAN'); return;}
      if (iban.length < 6){setSpnIban(true); setAltIban('El campo debe tener más de 6 dígitos'); return;}
      if (!iban2){setSpnIban2(true); setAltIban2('Confirma el número IBAN'); return;}
      if (iban2 !== iban){setSpnIban2(true); setAltIban2('El número IBAN no es igual'); return;}
    }

    if (!numeroCuenta) {setSpnNCuenta(true); setAltNCuenta('Ingresa el número de la cuenta'); return;}
    if (!numeroCuenta2) {setSpnNCuenta2(true); setAltNCuenta2('Confirma el número de la cuenta'); return;}
    if (numeroCuenta2 !== numeroCuenta){setSpnNCuenta2(true); setAltNCuenta2('El número de cuenta no es igual'); return;}

    info = {
      banco: banco === 'Otro' ? oBanco : banco,
      titular: titular,
      ...documento && {documento: tipoDoc+' '+documento},
      ...bsb && {bsb: tranSpaces(bsb)},
      ...tipoCuenta && {tipo: tipoCuenta},
      ...iban && {iban: tranSpaces(iban)},
      cuenta: tranSpaces(numeroCuenta),
      pais: country
    };
    
    return info
  }

  useEffect(()=>{setBanco(''); setOtro(false); setOBanco(''); setBsb(''); setDocumento(''); setIban('')},[country])
  
  const [documento, setDocumento] = useState('');
  const [spnDocumento, setSpnDocumento] = useState(false);
  const [altDocumento, setAltDocumento] = useState('');
  const [tipoDoc, setTipoDoc] = useState('CC');
  const documentos = [{id:1, text: 'CC'}, {id:2, text: 'Nit'}]

  return <>
      <Selector label={'Banco'} value={banco} setValue={setBanco} options={banks} 
        spn={spnNBanco} setSpn={setSpnNBanco} alt={altNBanco} />

      {otro && <Input label={'Nombre del Banco'} value={oBanco} setValue={setOBanco} spn={spnOBanco}
        setSpn={setSpnOBanco} alt={altOBanco} type={'text'} fill={'on'} name="banco" />}

      <Input label={'Nombre titular'} value={titular} setValue={setTitular} spn={spnTitular}
        setSpn={setSpnTitular} alt={altTitular} type={'text'} fill={'on'} name="titular" />
  
      {country === 'Colombia' && <>
        <SelectAndInput label={'Documento'} value={documento} setValue={setDocumento} spn={spnDocumento} setSpn={setSpnDocumento}
        alt={altDocumento} options={documentos} setOption={setTipoDoc} option={tipoDoc}/>
    
        <Selector label={'Tipo de Cuenta'} value={tipoCuenta} setValue={setTipoCuenta} options={tiposCuenta}
        spn={spnTipo} setSpn={setSpnTipo} alt={altTipo} />
      </>}

      {country === 'Australia' && 
        <div className='rowInput2'>
          <Input label={'Número BSB'} value={bsb} setValue={setBsb} spn={spnBsb}
            setSpn={setSpnBsb} alt={altBsb} type={'number'} fill={'off'} name="bsb" />
          <Input label={'Confirma Número BSB'} value={bsb2} setValue={setBsb2} spn={spnBsb2}
            setSpn={setSpnBsb2} alt={altBsb2} type={'number'} fill={'off'} name="bsb" />
        </div>
      }

      {country === 'EAU' && 
        <div className='rowInput2'>
          <Input label={'IBAN'} value={iban} setValue={setIban} spn={spnIban}
            setSpn={setSpnIban} alt={altIban} type={'text'} fill={'off'} name="IBAN" />
          <Input label={'Confirma IBAN'} value={iban2} setValue={setIban2} spn={spnIban2}
            setSpn={setSpnIban2} alt={altIban2} type={'text'} fill={'off'} name="IBAN" />
        </div>
      }
      
      <div className='rowInput2'>
        <Input label={'Número de cuenta'} value={numeroCuenta} setValue={setNumeroCuenta} spn={spnNCuenta}
          setSpn={setSpnNCuenta} alt={altNCuenta} type={'number'} fill={'off'} name="numeroCuenta" />
        <Input label={'Confirma Número de cuenta'} value={numeroCuenta2} setValue={setNumeroCuenta2} spn={spnNCuenta2}
          setSpn={setSpnNCuenta2} alt={altNCuenta2} type={'number'} fill={'off'} name="numeroCuenta" />
      </div>
    </> 
})

export default SetBank;