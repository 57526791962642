import React, {useRef, useState} from 'react'
import { useConfig } from '../../../../context/ConfigsContext';
import { useNavigate } from 'react-router-dom';
import { getUnixTime } from 'date-fns';
import { AddAccount } from '../../../../data/ManageReceiver';
import { ModalValidar } from '../../../../components/modales/Modales';
import { HuboUnError, PopUpValid } from '../../../../components/mensajes/MensajesClient';
import { BtnGeneral } from '../../../../components/buttons/Boton';
import Check from '../../../../img/icons/tick-square.svg';
import ImgPrev from '../../../../img/icons/arrow-left-1.svg';
import SetBank from '../../../../components/forms/SetBank';
import { SelectorOp } from '../../../../components/inputs/Selector';
import ImgAu from '../../../../img/icons/au.svg';
import ImgNz from '../../../../img/icons/nz.svg';
import ImgCo from '../../../../img/icons/co.svg';
import ImgEau from '../../../../img/icons/eau.svg'

const SelfAccount = (props) => {

  const { userID, numero, setStart } = props;
  const Navigate = useNavigate();
  const { OpenModal, setProcesando } = useConfig();
  const fecha = new Date();
  const [pais, setPais] = useState('Australia');
  const childRef = useRef();

  const paises = [
    {id: '1', text: 'Australia', img: ImgAu},
    {id: '2', text: 'Colombia', img:ImgCo},
    {id: '3', text: 'EAU', img: ImgEau},
    {id: '4', text: 'Nueva Zelanda', img: ImgNz},
  ]

  const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}
 
  const Validate = (e) =>{
    e.preventDefault();
    var info = childRef.current.validarCuenta()
    if(info){
      OpenModal(
        <ModalValidar 
        accion={()=>Submit(info)} 
        titulo={'Crear Cuenta'}
        mensaje={'¿Deseas Agregar esta Cuenta?'}
        botonName={'Sí, Agregar'}
        botonClass={'btnGeneral primaryC'}
      />)
    }
  }

  const Submit = (cuenta) =>{
    toUp();
    setProcesando(true);
    AddAccount({
      id: userID,
      numero: numero,
      datos:{
        ...cuenta,
        date: getUnixTime(fecha)
      }
    }).then(()=>{
      OpenModal(<PopUpValid mensaje={'Se ha registrado tu cuenta Correctamente.'} />);
      setProcesando(false)
      Navigate('/destinatarios')
    }).catch((error) => {
      console.log(error)
      setProcesando(false)    
      OpenModal(<HuboUnError />)
    })
  }

  return (
    <form onSubmit={Validate} className='windowContent'>
      <SelectorOp label={'País'} value={pais} setValue={setPais} options={paises} />

      <SetBank ref={childRef} country={pais}/>
      <div className='btnsCase'>
        <BtnGeneral text='Regresar' img={ImgPrev} side={'L'} disabled={false}
          type="button" action={()=>{setStart(false)}} clases={'primaryC'} large={false} />
        <BtnGeneral text='Registrar' img={Check} side={'L'} disabled={false}
          type="button" action={Validate} clases={'primaryC'} large={true} />
      </div>
    </form>
  )
}

export default SelfAccount;