import React from "react";
import { useConfig } from "../../../../context/ConfigsContext";
import { useNavigate } from 'react-router-dom';
import { ModalValidar } from "../../../../components/modales/Modales";
import { HuboUnError, PopUpValid } from "../../../../components/mensajes/MensajesClient";
import { DeleteDestino, DeleteAccount } from "../../../../data/ManageReceiver";
import { useAuth } from "../../../../context/AuthContext";

const DeleteReceiver = ({id}) =>{

  const Navigate = useNavigate();
  const { OpenModal, setProcesando } = useConfig();

  const Borrar = () =>{
    setProcesando(true)
    DeleteDestino({
      id: id,
    }).then(()=>{
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'El Destinatario se ha eliminado Correctamente'} />);
      Navigate('/destinatarios');
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar 
      accion={Borrar} 
      titulo={'Eliminar Destinatario'}
      mensaje={'¿Seguro deseas Eliminar este Destinatario?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

const BorrarAccount = ({id, cuentas, setCuentas}) =>{

  const { infoUser } = useAuth();
  const { OpenModal, setProcesando } = useConfig();

  const Eliminar = async() =>{
    const result = cuentas.filter(item => item.indice !== id)

    const accounts = result.reduce((a, v, i) => ({...a, 
      ['cuenta'+(i+1)]: {
        ...v
      }}
    ), {}) 
    await DeleteAccount({
      user: infoUser.usuario,
      cuentas: accounts
    }).then(()=>{
      setCuentas(Object.keys(accounts).map(key => 
        ({...accounts[key], indice: key})))
      setProcesando(false)
      OpenModal(<PopUpValid mensaje={'La cuenta se ha eliminado Correctamente'} />);
    }).catch(()=>{
      setProcesando(false)
      OpenModal(<HuboUnError />);
    });
  }

  return(
    <ModalValidar
      accion={Eliminar} 
      titulo={'Eliminar Cuenta'}
      mensaje={'¿Seguro deseas Eliminar esta Cuenta?'}
      botonName={'Sí, Eliminar'}
      botonClass={'btnGeneral redC'}
    />
  )
}

export {
  DeleteReceiver,
  BorrarAccount
};