import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from '../../context/ConfigsContext';
import {FiltroLabel} from '../../components/tablas/FiltroLabel';
import ColorEstado from '../../functions/ColorEstado';
import { LoadingCard } from '../../components/loading/Loadings';
import { BtnActions, BtnLink } from '../../components/buttons/Boton';
import ImgPlus from '../../img/icons/plus.svg';
import ImgFilter from '../../img/icons/filter-square.svg';
import '../../styles/table.css';
import { FiltroFechas } from './FiltroTrans';
import PaginacionNew from '../paginacion/PaginacionNew';

const TablaTransUser = (props) => {

	const {
		country,
		datos, 
		load, 
		filtros,
		setFiltros,
		setBusqueda,
		Next,
		After,
		first,
		last
	} = props;

  const Navigate = useNavigate();
	const {FormatAud, FormatCop, FormatNzd, FormatEau, dateEmail, Capitalize} = useConfig();
	const [filtrar, setFiltrar] = useState(false);

	const GoinTo = (link) =>{
		if(country === 'transferencias'){
			Navigate(`/transferencia/australia/${link}`)
		}if(country !== 'transferencias'){
      if(country === 'emiratos'){
			  Navigate(`/transferencia/emiratosArabesUnidos/${link}`)
		  }else{
			Navigate(`/transferencia/${country}/${link}`)
		}
  }
	}

  const CoinTo = (number) =>{
		if(country === 'transferencias'){return FormatAud(number)}
		if(country === 'colombia'){return FormatCop(number)}
		if(country === 'newzealand'){return FormatNzd(number)}
		if(country === 'emiratos'){return FormatEau(number)}
	}

	const CoinFrom = (number) =>{
		if(country === 'transferencias'){return FormatCop(number)}
		if(country === 'colombia'){return FormatAud(number)}
		if(country === 'newzealand'){return FormatCop(number)}
		if(country === 'emiratos'){return FormatCop(number)}
	}

	return(
		<>
			<div className='filtrosHeader'> 
				<BtnActions Clases={'GreenColor'} Action={()=>{setFiltrar(!filtrar); if(filtrar){setBusqueda({})}}} Img={ImgFilter} Text={'Filtrar'} Disbaled={false} />
				<FiltroLabel filtros={filtros} setFiltros={setFiltros} />
			</div>
			{filtrar && <FiltroFechas busqueda={setBusqueda} />}
			{load ? <LoadingCard /> : datos.length > 0 ? <>
				<div className="cardsTable">
					{datos.map((transferencia)=>{
						let divMetodo = 'blueLabel';
						if(transferencia.transferencia.metodo !== 'Tradicional'){
							divMetodo = ('redLabel');
						}
						return(
							<div className="cardTable"  onClick={()=>{GoinTo(transferencia.id)}} key={transferencia.id}>
								<h3 className={divMetodo}>{transferencia.id.substring(0, 6).toUpperCase()+' - '+transferencia.transferencia.metodo}</h3>
								<div className='infoDiv'>
									<p><label>Fecha</label>{dateEmail(transferencia.fecha).toUpperCase()}</p>
									<p className='pElipsis'><label>Destinatario</label>
									{Capitalize(transferencia.destinatario.nombre) + ' ' + Capitalize(transferencia.destinatario.apellido)}</p>
									<p><label>Estado</label>
									<span className={ColorEstado(transferencia.estado)}>&#9679;</span> {transferencia.estado}</p>
								</div>
								<div className='infoDiv'>
									<p><label>Valor a Recibir</label>{CoinTo(transferencia.transferencia.to ? transferencia.transferencia.to : transferencia.transferencia.montoAud)}</p>
									<p><label>Valor Pagado</label>{CoinFrom(transferencia.transferencia.from ? transferencia.transferencia.from : transferencia.transferencia.totalPagado)}</p>
									<p><label>Tasa de Cambio</label>{FormatCop(transferencia.transferencia.tasa)}</p>
								</div>
							</div>
						)
					})}
				</div>
        <div className='tablaGeneralDiv'>
          <table className='tablaGeneral'>
						<thead>
							<tr>
								<th>Referencia</th>
								<th>Fecha</th>
								<th>Destinatario</th>
								<th className='tdRight'>Tasa</th>
								<th className='tdRight'>Pagado</th>
								<th className='tdRight'>Reciben</th>
								<th>Estado</th>
							</tr>
						</thead>
						<tbody>
							{datos.map((transaccion) => {
								let divMetodo = 'Tradicional';
								if(transaccion.transferencia.metodo !== 'Tradicional'){divMetodo = ('Referencia');}

								return(
									<tr key={transaccion.id} onClick={()=>{GoinTo(transaccion.id)}}>
										<td className='tdLeft'>
											{transaccion.id.substring(0, 6).toUpperCase()}<div className={divMetodo}>{transaccion.transferencia.metodo}</div>
										</td>
										<td className='tdLeft'>{dateEmail(transaccion.fecha).toUpperCase()}</td>
										<td>{transaccion.cliente && (Capitalize(transaccion.cliente.nombre))}
											{transaccion.cliente && <br/>}
											{Capitalize(transaccion.destinatario.nombre) + ' ' + Capitalize(transaccion.destinatario.apellido)}</td>
										<td className='tdRight'><span>{FormatCop(transaccion.transferencia.tasa)}</span></td>
										<td className='tdRight'><span>{CoinFrom(transaccion.transferencia.from ? transaccion.transferencia.from : transaccion.transferencia.totalPagado)}</span>
											<p className='smallText'>{transaccion.colcambios.banco}</p></td>
										<td className='tdRight'><span>{CoinTo(transaccion.transferencia.to ? transaccion.transferencia.to : transaccion.transferencia.montoAud)}</span>
											<p className='smallText'>{transaccion.cuenta.banco}</p></td>
										<td><span className={ColorEstado(transaccion.estado)+' marginR5'}>&#9679;</span>{transaccion.estado}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</div>
				<div className="TableFooter">
					<PaginacionNew Next={Next} After={After} first={first} last={last} />
				</div>
			</> : filtros.length > 0 ?
				<div className="itemsEmpty">
					<p>No se encontraron resultados<span>Por favor verifica los filtros de búsqueda.</span></p>
				</div>
			:
			<div className="itemsEmpty">
				<p>Aún no tienes transferencias<span>Te mostraremos tus transferencias aquí.</span></p>
				<BtnLink link="/nueva-transferencia"  clases='primaryC' large={false} img={ImgPlus} side='L' text='Nueva Transferencia' />
			</div>
			}
		</>
	)
}

export default TablaTransUser;
