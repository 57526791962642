import React, { useState, useContext, useEffect} from 'react';
import { useGetMenus } from '../hooks/useGetData';
import { numericFormatter } from 'react-number-format';
import { format, fromUnixTime } from 'date-fns';
import { es } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';

const ConfigContext = React.createContext();

const useConfig = () => {
  return useContext(ConfigContext);
}

const ConfigProvider = ({children}) => {

	const [theme, setTheme] = useState('light');
	const [menuOpen, setMenuOpen] = useState(false);
	const [sideFix, setSideFix] = useState(true);
	const [listMenu] = useGetMenus();

	const SwitchTheme = () =>{
		const newTheme = theme === 'light' ? 'dark' : 'light';
		localStorage.setItem('theme', newTheme);
		setTheme(newTheme)
	}

	useEffect(()=>{
		const o = localStorage.getItem('theme');
		setTheme(o)
	},[])

	const [OpenMenu, setOpenMenu] = useState(false);

	const [modalStatus, setModalStatus] = useState(false);
	const [ComponentM, setComponentM] = useState();
	const [modal, cambiarModal] = useState('modal');

	const OpenModal = (component) => {
		setModalStatus(true);
		setComponentM(component);
		setTimeout(() => {
			cambiarModal('modal modalAct');
		}, 400);
	}
	const CloseModal = (e) => {
		e.preventDefault();
		cambiarModal('modal')
		setModalStatus(false)
		setComponentM();
	}

    const [procesando, setProcesando] = useState(false);

	const FormatCop = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:'.', decimalSeparator:',', prefix:'$', suffix:' COP', decimalScale:'2'}) 
	}

	const FormatAud = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AUD', decimalScale:'2'})
	}

	const FormatUsd = (value) =>{
		const valor = value.toString();
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' USD', decimalScale:'2'})
	}

	const FormatNzd = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' NZD', decimalScale:'2'})
	}

	const FormatEau = (value) =>{
		const valor = value ? value.toString() : '0' ;
		return numericFormatter(valor, {thousandSeparator:',', decimalSeparator:'.', prefix:'$', suffix:' AED', decimalScale:'2'})
	}

	const dateFormat = (fecha) => {
		return fecha ? format(fromUnixTime(fecha), "dd 'de' MMMM 'de' yyyy", { locale: es }) : 'Fecha inválida';
	}

	const dateNumbers = (fecha) => {
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Australia/Canberra", "dd'/'MM'/'yy") : 'Fecha inválida';
	}

	const dateAu = (fecha) => {
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Australia/Canberra", "dd'/'MM'/'yy", { locale: es }) : 'Fecha inválida';
	}

	const dateEau = (fecha) => {
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Asia/Dubai", "dd'/'MM'/'yy", { locale: es }) : 'Fecha inválida';
	}

	const dateDayMonth = (fecha) => {
		return fecha ? format(fromUnixTime(fecha), "dd 'de' MMMM", { locale: es }) : 'Fecha inválida';
	}

	const dateEmail = (fecha) => {
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Australia/Canberra", "dd'/'MM'/'yy", { locale: es }) : 'Fecha inválida';
	}

	const horaCO = (fecha) => {
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "America/Bogota", "MMMM dd, yyyy · hh:mm a", { locale: es }) : 'Fecha inválida';
	};
    
	const horaAU = (fecha) =>{
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Australia/Canberra", "MMMM dd, yyyy · hh:mm a", { locale: es }) : 'Fecha inválida';
	};

	const horaNZ = (fecha) =>{
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Pacific/Auckland", "MMMM dd, yyyy · hh:mm a", { locale: es }) : 'Fecha inválida';
	};

	const horaEAU = (fecha) =>{
		return fecha ? formatInTimeZone(fromUnixTime(fecha).toISOString(), "Asia/Dubai", "MMMM dd, yyyy · hh:mm a", { locale: es }) : 'Fecha inválida';
	};

	const DateNew = (fecha) => { return format(fromUnixTime(fecha), "dd'-'MM'-'yyyy", { locale: es }); };
  const DateOld = (fecha) => { return format(new Date(fecha), "dd'-'MM'-'yyyy", { locale: es }); };
	
	const Capitalize = (word) =>{
		if(word){
			const words = word.split(" ");
			for (let i = 0; i < words.length; i++) {
				if(words[i]){
					words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
				}
			}
			return words.join(" ");
		}else{return ''}
	}

	const toUp =()=>{window.scrollTo({ behavior: 'smooth', top: '0px' })}
	const EmailTest = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
    
	return(
		<ConfigContext.Provider 
			value={{
				CloseModal, 
				OpenModal,
				modalStatus,
				ComponentM,
				modal,
				OpenMenu,
				setOpenMenu,
				SwitchTheme,
				theme,
				procesando,
				setProcesando,
				FormatCop,
				FormatAud,
				FormatUsd,
				FormatNzd,
				FormatEau,
				menuOpen,
				setMenuOpen,
				sideFix,
				setSideFix,
				listMenu,
				dateFormat,
				dateNumbers,
				dateDayMonth,
				dateEmail,
				dateAu,
				dateEau,
				horaAU,
				horaCO,
				horaNZ,
				horaEAU,
				Capitalize,
				toUp,
				EmailTest,
				DateNew,
				DateOld
			}}>
			{children}
		</ConfigContext.Provider>
	);
}

export {ConfigProvider, ConfigContext, useConfig};